import { IconButton as Component } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

export const IconButton = styled(Component)`
  border: 1px solid transparent;
  transition: 0.4s ease border, 0.4s ease box-shadow;

  &:hover {
    border-color: var(--chakra-colors-secondary-400);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  }
`;
