import { Button, Divider, Flex, HStack, Kbd, Spinner, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useIdentity } from 'hooks/useIdentity';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { BiCog, BiFullscreen } from 'react-icons/bi';
import { useDeckContext } from '../../contexts/DeckContext';
import { useFullScreenContext } from '../../contexts/FullScreenContext';
import { IconBarButton } from '../IconBar/IconBarButton';
import { Logo } from '../Logo/Logo';
import { UserMenu } from '../UserMenu/UserMenu';
import { SliderNavigation, topMenuStyle } from './subcomponents/SliderNavigation';

const TopMenuButton = styled(IconBarButton)(topMenuStyle);

const TopMenuWrapper = styled(Flex)`
  --top-menu-space: var(--chakra-space-4);

  align-items: center;
  border-bottom: 1px solid var(--sii-colors-border);
  font-size: var(--chakra-fontSizes-md);
  height: var(--header-height);
  justify-content: space-between;
  padding: 0 var(--chakra-space-4);
`;

export const TopMenu = () => {
  const { activeDeck, loading, openSettings, publishDeck, readonly } = useDeckContext();
  const { user } = useIdentity();
  const { enterFullScreen } = useFullScreenContext();

  const makeFullscreen = () => {
    const slideWrapper = document.getElementById('slide-wrapper');
    slideWrapper && enterFullScreen(slideWrapper);
  };

  useKeyboardShortcut('fullscreen', makeFullscreen);

  return (
    <TopMenuWrapper>
      <Flex alignItems="center" gridGap="var(--top-menu-space)" flex={1}>
        <Logo height="24px" />
        <Flex alignItems="center" gridGap="var(--top-menu-space)">
          {user ? (
            <>
              <Text color="text.500" display="inline-block" position="relative" top="3px">
                {activeDeck?.title}
              </Text>{' '}
              {loading ? (
                <>
                  <Spinner color="brand.200" size="sm" /> {loading}
                </>
              ) : readonly ? null : (
                <IconBarButton icon={BiCog} label="Deck Settings" onClick={openSettings} size="sm" />
              )}
            </>
          ) : null}
        </Flex>
      </Flex>
      {!readonly && (
        <Text color="text.400" fontSize="sm">
          Type <Kbd>?</Kbd> for help.
        </Text>
      )}
      <Flex justifyContent="end" gridGap="var(--top-menu-space)" flex={1}>
        <Flex gridGap="var(--top-menu-space)">
          <HStack>
            <SliderNavigation isDisabled={!!loading} />
            <TopMenuButton
              size="sm"
              icon={BiFullscreen}
              isDisabled={!!loading}
              label="Fullscreen ⇧⌘F"
              onClick={makeFullscreen}
              variant="outline"
            />
            {user && !readonly && (
              <Button disabled={activeDeck?.isPublished} onClick={() => publishDeck()}>
                Published
              </Button>
            )}
          </HStack>
          <Divider orientation="vertical" />
        </Flex>
        <Flex whiteSpace="nowrap">
          <UserMenu />
        </Flex>
      </Flex>
    </TopMenuWrapper>
  );
};
