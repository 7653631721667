import { User } from 'react-netlify-identity';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { cloneDeck } from 'utils/cloneDeck';
import { postFetcher } from 'utils/fetchers';
import { log } from 'utils/logger';

type ReturnType = [Deck[], (decks: Deck[]) => Promise<Deck[] | undefined>];

export const useDeckList = ({ user }: { user?: User }): ReturnType => {
  const { deckId, username } = useParams();
  const navigate = useNavigate();

  // If the user is logged in, load all their decks
  const { data: userDecks = [], mutate } = useSWR<Deck[]>(
    user ? `/.netlify/functions/getDeck?userId=${user.id}` : null,
  );

  // Check whether the current user owns the deck that we're loading
  const userOwnsDeck = !!userDecks?.find((d) => d.id === deckId || d.slug === deckId);

  // If we haven't already loaded the deck as part of the user's list
  let { data: requestedDeck = [] } = useSWR<Deck[]>(
    !userOwnsDeck && deckId
      ? `/.netlify/functions/getDeck?deckId=${deckId}&userId=${username}${user?.id ? `&currentUserId=${user.id}` : ''}`
      : null,
  );
  // @ts-ignore
  if (requestedDeck.error) {
    log('useDeckList: requestedDeck.error', JSON.stringify(requestedDeck));
    requestedDeck = [];
    navigate('/');
  }

  const allDecks = [...userDecks, ...requestedDeck];

  let { data: defaultDeck = [] } = useSWR<Deck[]>(
    allDecks.length ? null : `/.netlify/functions/getDeck?deckId=326a8143-9dc4-42d6-af5c-933f99b8d5bc`,
  );

  const { data: userDefaultDeck } = useSWR<Deck[]>(
    user && !allDecks.length && defaultDeck.length ? `/.netlify/functions/saveDeck` : null,
    // @ts-ignore
    postFetcher(
      JSON.stringify({
        deck:
          user && !allDecks.length && defaultDeck.length
            ? { ...cloneDeck({ ...defaultDeck[0] }), userId: user.id }
            : {},
      }),
    ),
  );

  // TODO: Need mutate for both functions ??? Do we even need both functions?
  return [[...userDecks, ...requestedDeck, ...(userDefaultDeck || defaultDeck)], mutate];
};
