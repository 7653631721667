import { useToast } from '@chakra-ui/react';
import { Notification, NotificationProps } from 'components/Notification/Notification';
import { Toast } from 'components/Toast/Toast';
import { cloneDeep } from 'lodash';
import React, { createContext, useCallback, useContext, useState } from 'react';

type NotificationConfig = NotificationProps & {
  header?: React.ReactNode;
  id: string;
  message: React.ReactNode;
  onClose?(confirmed: boolean): void;
};

type NotificationContextType = {
  closeNotice(id: string, confirm?: boolean): void;
  notifications: NotificationConfig[];
  notify(config: NotificationConfig): void;
};

export const NotificationContext = createContext({} as NotificationContextType);

export const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationConfig[]>([]);
  const toast = useToast();

  const close = useCallback((id, confirm) => {
    setNotifications((currentList) => {
      const list = cloneDeep(currentList);
      return list.filter((n) => {
        if (n.id === id) {
          n.onClose && n.onClose(confirm);
          return false;
        } else {
          return true;
        }
      });
    });
  }, []);

  const open = useCallback(
    (config: NotificationConfig) => {
      if (config.type === 'toast') {
        toast({
          id: config.id,
          status: config.level,
          duration: 5000,
          isClosable: true,
          position: 'bottom-right',
          render: () => <Toast {...config} />,
        });
      } else {
        setNotifications((currentList) => {
          const foundItem = currentList.find((item) => item.id === config.id);
          const list = cloneDeep(currentList);
          if (!foundItem) {
            list.push(config);
          }
          return list;
        });
      }
    },
    [toast],
  );

  return (
    <NotificationContext.Provider value={{ closeNotice: close, notify: open, notifications }}>
      {notifications.map((config) => (
        <Notification {...config} key={config.id} onClose={(confirm) => close(config.id, confirm)} />
      ))}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
