import useKeyboardHelper from 'use-keyboard-shortcut';
import { kbShortcuts, ShortcutTypes } from 'utils/keyboard';

export const useKeyboardShortcut = (key: ShortcutTypes, cb: () => void) => {
  const shortcut = kbShortcuts[key];

  useKeyboardHelper(shortcut.shortcutKeys, cb, {
    overrideSystem: shortcut.overrideSystem || false,
    ignoreInputFields: true,
  });
};
