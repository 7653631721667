import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { times } from 'lodash';

const FontPreview = styled(Box)`
  --size: 21px;
  background-color: var(--chakra-colors-secondary-50);
  border-radius: 2px;
  color: var(--chakra-colors-text-200);
  height: var(--size);
  overflow: hidden;
  position: relative;
  width: var(--size);
  transition: var(--sii-animation-exit) transform;

  &:hover {
    background-color: var(--chakra-colors-secondary-200);
    transition: var(--sii-animation-enter) transform;
  }

  &:after {
    content: 'Aa';
    line-height: 0.5rem;
    position: absolute;
    bottom: 0.1rem;
    right: 0;
  }
`;

type Props = {
  fontWeight?: number;
  onChange(size: number): void;
};

const fontWeights = times(9, (i) => (i + 1) * 100);

export const FontWeightMenu: React.FC<Props> = ({ fontWeight, onChange }) => (
  <VStack>
    {fontWeights.map((weight) => (
      <Button
        aria-label={`${weight}`}
        leftIcon={<FontPreview fontWeight={weight} />}
        colorScheme={fontWeight === weight ? 'brand' : 'gray'}
        key={weight}
        onClick={() => onChange(weight)}
        variant="outline"
      >
        <Flex gap="10px" justifyContent="space-between" width="100%">
          <Box style={{ color: fontWeight === weight ? 'text.200' : 'text.400' }}>{weight || 400}</Box>
        </Flex>
      </Button>
    ))}
  </VStack>
);
