import { Text as Component } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import React from 'react';

const textStyle = `
  font-family: var(--sliide-text-font-family);

  a {
    text-decoration: underline;
  }

  img {
    display: inline-block;
    vertical-align: baseline;
  }

  code {
    background-color: var(--chakra-colors-red-100);
    border-radius: 4px;
    color: var(--chakra-colors-red-600);
    display: inline-block;
    font-size: 0.8em;
    line-height: 1em;
    padding: 4px 6px;
  }

  ul {
    display: grid;
    gap: 1rem;
    list-style-type: none;
    padding: 1.4rem 0rem;
  }
  li {
    display: inline-block;
    line-height: 100%;
    margin: 0;
    padding-left: 2rem;
    position: relative;

    &:before {
      content: '• ';
      font-size: 200%;
      left: 0;
      height: 100%;
      opacity: 0.5;
      position: absolute;
    }
  }
`;

const StyledText = styled(Component)(textStyle);

export const Text: React.FC<SectionContentTextProps> = ({ className, contentId, htmlContent: __html, meta = {} }) => {
  return (
    <motion.div
      initial={{ opacity: 0, left: -10 }}
      variants={{ blockVisible: { opacity: 1, left: 0 } }}
      style={{ position: 'relative' }}
    >
      <StyledText
        bgColor={meta.bgColor || 'transparent'}
        className={className}
        color={meta.fontColor}
        dangerouslySetInnerHTML={__html.length ? { __html } : { __html: '&nbsp;' }}
        fontFamily={meta.fontFamily}
        fontSize={meta.fontSize}
        fontWeight={meta.fontWeight}
        data-testid={`text-block-${contentId}`}
        lineHeight={`calc(1.2 * var(--chakra-fontSizes-${meta.fontSize}))`}
        textAlign={meta.textAlign}
        // @ts-expect-error
        style={{ '--sliide-text-font-family': meta.fontFamily }}
      />
    </motion.div>
  );
};
