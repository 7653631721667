import { cloneDeep } from 'lodash';

export const reorderItems = (dragIndex: number, hoverIndex: number, items: any[]) => {
  const dragItem = items[dragIndex];
  const newItems = cloneDeep(items);
  // Remove the dragged item
  newItems.splice(dragIndex, 1);
  // Insert in the correct location
  newItems.splice(hoverIndex, 0, dragItem);
  return newItems;
};
