import React, { createContext, useContext } from 'react';
import { useFullScreen } from '../hooks/useFullScreen';

type ContextState = { enterFullScreen(e: HTMLElement): void; exitFullScreen(): void; isFullScreen: boolean };

export const FullScreenContext = createContext<ContextState>({ isFullScreen: false } as ContextState);

export const FullScreenProvider: React.FC = ({ children }) => {
  const { enterFullScreen, exitFullScreen, isFullScreen } = useFullScreen();

  return (
    <FullScreenContext.Provider value={{ isFullScreen, enterFullScreen, exitFullScreen }}>
      {children}
    </FullScreenContext.Provider>
  );
};

export const useFullScreenContext = () => {
  return useContext(FullScreenContext);
};
