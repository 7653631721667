import { ModalContent } from '@chakra-ui/react';
import { Modal, ModalProps } from 'components/Modal/Modal';
import React, { DetailedHTMLProps, FormHTMLAttributes } from 'react';
import { log } from 'utils/logger';

type FormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

const FormModalContent: React.FC<FormProps> = ({ children, ...formProps }) => {
  log('FormModalContent.render', formProps.key);
  return (
    <ModalContent
      maxWidth="468px"
      style={{ '--border-color': 'var(--chakra-colors-secondary-200)', willChange: 'transform' } as React.CSSProperties}
    >
      <form {...formProps}>{children}</form>
    </ModalContent>
  );
};

type Props = ModalProps & { formProps?: FormProps; onSubmit?: FormProps['onSubmit'] };

export const FormModal: React.FC<Props> = ({ components, formProps, onSubmit, ...props }) => {
  return (
    <Modal
      components={{
        ...components,
        Content: ({ children }) => (
          <FormModalContent key={props.isOpen ? 'open' : 'closed'} onSubmit={onSubmit} {...formProps}>
            {children}
          </FormModalContent>
        ),
      }}
      {...props}
    />
  );
};
