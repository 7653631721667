import { forEach, groupBy, uniq } from 'lodash';
import { log } from './logger';

// const googleFontUrl = `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.GOOGLE_API_KEY}`;
const googleFontUrl = `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyApXFIx48MOxoey79f90lWfmCrt2HAmByY`;

export const fontCategoryMap: { [key: string]: string } = {
  display: 'Display',
  handwriting: 'Handwriting',
  monospace: 'Monospace',
  'sans-serif': 'Sans Serif',
  serif: 'Serif',
};

/**
 * getAllFonts - parses through  a list of slides to find all fonts used
 * @param slides
 * @returns string[]
 */
export const getAllFonts = (slides: Slide[]) => {
  const fonts: string[] = [];
  slides.forEach((slide) => {
    forEach(slide.sections, (section: Section) => {
      section.meta?.fontFamily && fonts.push(section.meta?.fontFamily);
      section.blocks?.length &&
        section.blocks.forEach((c) => {
          // @ts-ignore
          c.meta?.fontFamily && fonts.push(c.meta?.fontFamily);
        });
    });
  });
  return uniq(fonts);
};

type GoogleFontMap = { [key: string]: GoogleFont[] };
export const loadFonts = async (): Promise<GoogleFontMap> => {
  const result = await (await fetch(googleFontUrl, {})).json();
  // @ts-ignore
  const loadedFonts = result.items as GoogleFont[];
  log('Loading Google Fonts...', loadedFonts);
  let groupedFonts: GoogleFontMap = {};

  forEach(
    groupBy(loadedFonts, (font) => font.category),
    (fontList, groupName) => {
      groupedFonts[groupName] = fontList.slice(0, 5);
    },
  );

  return groupedFonts;
};
