import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import React, { useRef } from 'react';

type Props = {
  content: React.ReactNode;
  header?: React.ReactNode;
  id?: string;
  isOpen: boolean;
  onClose(confirm?: boolean): void;
  variant?: string;
};

export const Dialog: React.FC<Props> = ({ content, header, id, isOpen, onClose, variant }) => {
  const cancelRef = useRef<FocusableElement>(null);

  return (
    <AlertDialog id={id} isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} variant={variant}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          {header && (
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>
          )}
          <AlertDialogBody p="var(--chakra-space-8) var(--chakra-space-12)">{content}</AlertDialogBody>
          <AlertDialogFooter>
            {/* @ts-ignore */}
            <Button ref={cancelRef} onClick={() => onClose(false)} tabIndex={-1} variant="ghost">
              Cancel
            </Button>
            <Button colorScheme="destroy" onClick={() => onClose(true)} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
