import React from 'react';
import { LiveEditor, LiveProvider } from 'react-live';
import { codeColors, codeTheme } from './theme';

type ImportType = {
  list: any;
  pkg: any;
};

type Props = {
  code: string;
  disabled?: boolean;
  imports?: ImportType[];
  onChange(value: string): void;
  scope?: any;
};

export const CodeBlock: React.FC<Props> = ({ code, disabled, imports = [], onChange, scope = {} }) => (
  <LiveProvider
    code={code}
    disabled={disabled}
    scope={{ ...scope, describe: () => {}, test: () => {} }}
    theme={codeTheme}
  >
    <div
      style={{
        fontFamily: 'var(--sii-font-code)',
        fontSize: '22px', // `${codeTheme.plain.fontSize}`,
      }}
    >
      {imports?.map(({ list, pkg }) => (
        <div>
          <span style={{ color: codeColors.keyword }}>import</span> {`{ ${list.join(', ')} }`}{' '}
          <span style={{ color: codeColors.keyword }}>from</span>{' '}
          <span style={{ color: codeColors.string }}>'{pkg}'</span>;
        </div>
      ))}
    </div>
    <LiveEditor onChange={(newCode) => newCode !== code && onChange(newCode)} style={{ margin: '0 -10px' }} />
  </LiveProvider>
);
