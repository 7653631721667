import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { DeckDrawer } from 'components/DeckDrawer/DeckDrawer';
import { KeyboardShortcutModal } from 'components/KeyboardShortcutModal/KeyboardShortcutModal';
import { Suspense } from 'react';
import { SWRConfig } from 'swr';
import './App.scss';
import { Deck } from './components/Deck/Deck';
import { TopMenu } from './components/TopMenu/TopMenu';
import { WelcomeModal } from './components/WelcomeModal/WelcomeModal';
import { AppProvider } from './contexts/AppProvider';
import { diapoTheme } from './utils/theme';

window.com = { sii: {} };

// TODO: Add ErrorBoundary
// class ErrorBoundary extends React.Component<any> {
//   state = { hasError: false, error: null };
//   static getDerivedStateFromError(error: any) {
//     return {
//       hasError: true,
//       error,
//     };
//   }
//   render() {
//     if (this.state.hasError) {
//       return this.props.fallback;
//     }
//     return this.props.children;
//   }
// }

export default function App() {
  return (
    <ChakraProvider theme={diapoTheme}>
      {/* <ErrorBoundary fallback={<h2>Could not fetch posts.</h2>}> */}
      <SWRConfig
        value={{
          fetcher: async (url) => {
            const result = await fetch(url);

            let data = [];
            try {
              data = await result.json();
            } catch (e) {
              console.error('Error while parsing json', e);
            }
            return data;
          },
          suspense: true,
        }}
      >
        <Suspense
          fallback={
            <div style={{ height: '100vh', width: '100vw' }}>
              <Spinner /> App loading...
            </div>
          }
        >
          <AppProvider>
            <main className="page">
              <TopMenu />
              <DeckDrawer />
              <Deck />
              <WelcomeModal />
              <KeyboardShortcutModal />
            </main>
          </AppProvider>
        </Suspense>
      </SWRConfig>
      {/* </ErrorBoundary> */}
    </ChakraProvider>
  );
}
