import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { log } from 'utils/logger';

export const usePagination = () => {
  const { deckId = 'demo-slides', slideIdx = 0, username = 'veiko' } = useParams();
  const navigate = useNavigate();

  const onNext = useCallback(() => {
    const url = `/u/${username}/d/${deckId}/${+slideIdx + 1}`;
    log('usePagination.onNext heading to', url);
    navigate(url);
  }, [deckId, navigate, slideIdx, username]);

  const onPrev = useCallback(() => {
    const prevIdx = +slideIdx - 1 < 0 ? 0 : +slideIdx - 1;
    const url = `/u/${username}/d/${deckId}/${prevIdx}`;
    log('usePagination.onPrev heading to', url);
    navigate(url);
  }, [deckId, navigate, slideIdx, username]);

  const setActive = useCallback(
    (a: number) => {
      const url = `/u/${username}/d/${deckId}/${a}`;
      log('usePagination.setActive heading to', url);
      navigate(url);
    },
    [deckId, navigate, username],
  );

  return [
    +slideIdx,
    {
      onNext,
      onPrev,
      goto: setActive,
    },
  ] as const;
};
