import { useNotificationContext } from 'contexts/NotificationContext';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

type UseConfirmDeleteDialogReturn = {
  confirmDialog(text: string): Promise<boolean>;
};

export const useConfirmDeleteDialog = (): UseConfirmDeleteDialogReturn => {
  const { notify } = useNotificationContext();

  const confirmDialog = useCallback(
    async (message: string) => {
      return new Promise<boolean>((resolve) => {
        notify({
          id: uuid(),
          header: 'Confirm Delete',
          message,
          onClose: (s: boolean) => resolve(s || false),
        });
      });
    },
    [notify],
  );

  return { confirmDialog };
};
