import { List as ListWrapper, ListIcon, ListItem } from '@chakra-ui/react';
import React from 'react';
import { MdCheckCircle } from 'react-icons/md';

type Props = SectionContentProps;

export const ListDisplay: React.FC<Props> = ({ value = [] }) => (
  <ListWrapper>
    {/* @ts-ignore Faked this for now, tmp.slides has this as an array but should be a string? */}
    {value.map((i: string, idx: number) => (
      <ListItem key={idx}>
        <ListIcon as={MdCheckCircle} color="green.500" />
        {i}
      </ListItem>
    ))}
  </ListWrapper>
);
