import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { BasicSlide } from 'components/BasicSlide/BasicSlide';
import { SlideDrawer } from 'components/SlideDrawer/SlideDrawer';
import { EditableProvider } from 'contexts/EditableContext';
import { SlideContext } from 'contexts/SlideContext';
import React, { useContext } from 'react';

const SlidesInner = styled.div`
  flex: 1;
`;

export const Deck: React.FC = () => {
  const { editMode } = useContext(SlideContext);

  // const { loadFonts } = useFontContext();
  // Load fonts for this deck
  // useEffect(() => {
  //   slides.length && loadFonts(getAllFonts(slides));
  // }, [loadFonts, slides]);

  return (
    <EditableProvider editMode={editMode}>
      <Flex flex={1} id="deck">
        <SlidesInner id="slide-wrapper">
          <BasicSlide />
        </SlidesInner>
        <SlideDrawer />
      </Flex>
    </EditableProvider>
  );
};
