import {
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from 'components/Button/Button';
import { useCallback, useEffect } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Logo } from '../Logo/Logo';

const CLOSE_WELCOME = 'close_welcome';

export const WelcomeModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { get, set } = useLocalStorage();

  useEffect(() => {
    const hasClosed = get(CLOSE_WELCOME) === 'true';
    !hasClosed && onOpen();
  }, [get]); // eslint-disable-line

  const close = useCallback(() => {
    set(CLOSE_WELCOME, 'true');
    onClose();
  }, [onClose, set]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent
          bgImage="linear-gradient(160deg, transparent 0%, transparent 50%, #fff 50%), linear-gradient(to right, white 20%,var(--chakra-colors-purple-200),  var(--chakra-colors-blue-300))"
          height="40vw"
          maxWidth="60vw"
        >
          <ModalCloseButton color="white" />
          <ModalBody padding="60px 60px 0">
            <Flex direction="column" height="100%" justifyContent="space-between">
              <div>
                <Logo height="40px" />
                <Text color="text.300">Slide deck creation for developers</Text>
              </div>
              <div>
                <img alt="" src="./welcome.png" width="50%" style={{ margin: 'auto' }} />
              </div>
              <Flex justifyContent="center">
                <Text
                  color="text.500"
                  fontSize="0.9rem"
                  lineHeight="1.6em"
                  textAlign="justify"
                  display="inline-block"
                  width="80%"
                >
                  Welcome to sliides! An interactive slide deck builder with features specifically tailored for devs.
                  Support for Live-coding, syntax-highlighting, iFrames, embedding videos.
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter bgColor="transparent" justifySelf="flex-end" padding="40px">
            <ButtonGroup size="lg">
              <Button borderRadius="30px" letterSpacing="-0.03em" mr={3} onClick={onClose} px={10}>
                Get Started!
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
