import { useDisclosure } from '@chakra-ui/react';
import { BiImage as ImageIcon } from 'react-icons/bi';
import { IconBarButton } from '../IconBar/IconBarButton';
import { ImageModal } from '../ImageModal/ImageModal';

type Props = {
  color?: string;
  label?: string;
  onChange(url: string, username?: string): void;
  onClose?(): void;
  url?: string;
};

export const ImageMenu: React.FC<Props> = ({ label = 'Background Image', onChange, url: urlProp = '' }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconBarButton label={label} icon={ImageIcon} onClick={onOpen} />
      <ImageModal isOpen={isOpen} onChange={onChange} onClose={onClose} url={urlProp} />
    </>
  );
};
