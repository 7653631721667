// TODO Do these options need to be used on some?
// { overrideSystem: true, ignoreInputFields: true }

type Shortcut = {
  description: string;
  overrideSystem?: boolean;
  shortcutKeys: string[];
};
export const kbShortcuts: { [key: string]: Shortcut } = {
  // drawer: { description: 'View All Slides', overrideSystem: true, shortcutKeys: ['d'] },
  help: { description: 'Help', shortcutKeys: ['?'] },
  fullscreen: { description: 'Enter fullscreen mode', shortcutKeys: ['⇧', '⌘', 'F'] },
  newdeck: { description: 'New Deck', overrideSystem: true, shortcutKeys: ['⇧', '⌘', 'D'] },
  newslide: { description: 'New Screen', overrideSystem: true, shortcutKeys: ['⇧', '⌘', 'S'] },
  next: { description: 'Next Slide', shortcutKeys: ['ArrowRight'] },
  prev: { description: 'Prev Slide', shortcutKeys: ['ArrowLeft'] },
  reset: { description: 'Go to start', shortcutKeys: ['g', '0'] },
  // search: { description: 'Search', shortcutKeys: ['s'] },
};

export type ShortcutTypes = keyof typeof kbShortcuts;
