import { Box, Flex, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { NotificationLevel, NotificationProps } from 'components/Notification/Notification';
import React from 'react';
import {
  BiErrorCircle as ErrorIcon,
  BiInfoCircle as InfoIcon,
  BiCheck as SuccessIcon,
  BiError as WarningIcon,
} from 'react-icons/bi';

const levelColor = {
  error: 'red',
  info: 'blue',
  success: 'green',
  warning: 'yellow',
};

const levelIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
};

const ToastWrapper = styled(Box)<{ level: NotificationLevel }>`
  background-image: var(--sii-gradient-${(p) => p.level});
  border-radius: 6px;
  color: white;
  padding: var(--chakra-space-2) var(--chakra-space-2) var(--chakra-space-2) var(--chakra-space-3);
  text-shadow: 1px 1px 2px var(--chakra-colors-${(p) => levelColor[p.level]}-600);
`;

export const Toast: React.FC<NotificationProps> = ({ header, level = 'warning', message }) => (
  <ToastWrapper level={level}>
    <Flex alignItems="center" gridGap={2}>
      <Icon as={levelIcon[level]} fontSize="1.6em" />
      <div>
        {header && <strong>{header}</strong>} {message}
      </div>
    </Flex>
  </ToastWrapper>
);
