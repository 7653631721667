import {
  Modal as Component,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { merge } from 'lodash';
import React from 'react';
import { log } from 'utils/logger';

type ComponentList = {
  Body: React.ComponentType<ModalBodyProps>;
  Content: React.ComponentType<ModalContentProps>;
  Footer: React.ComponentType<ModalFooterProps>;
  Header: React.ComponentType<ModalHeaderProps>;
};
const componentList: ComponentList = {
  Body: ModalBody,
  Content: ModalContent,
  Footer: ModalFooter,
  Header: ModalHeader,
};

type TabConfig = {
  content: React.ReactNode;
  title: string;
};

export type ModalProps = {
  components?: Partial<ComponentList>;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  isOpen: boolean;
  onClose?(): void;
  tabs?: TabConfig[];
};

export const Modal: React.FC<ModalProps> = ({
  children,
  components,
  footer,
  header,
  isOpen = false,
  onClose = () => {},
  tabs = [],
}) => {
  const { Body, Content, Footer, Header } = merge(componentList, components);
  log('Modal.render', { isOpen });

  return (
    <Component isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Content
        maxWidth="468px"
        style={
          { '--border-color': 'var(--chakra-colors-secondary-200)', willChange: 'transform' } as React.CSSProperties
        }
      >
        <ModalCloseButton alignSelf="flex-start" top="var(--chakra-space-3)" />
        {header && <Header>{header}</Header>}
        {tabs.length > 1 ? (
          <Tabs align="center">
            <Body padding="0">
              <TabList borderBottomWidth="1px" height="60px">
                {tabs.map((tab: TabConfig) => (
                  <Tab key={tab.title}>{tab.title}</Tab>
                ))}
              </TabList>
            </Body>
            <TabPanels>
              {tabs.map((tab: TabConfig) => (
                <TabPanel key={`${tab.title}-panel`} padding={0}>
                  <Body padding="20px">{tab.content}</Body>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        ) : (
          <Body>{tabs[0]?.content || children}</Body>
        )}
        {footer && <Footer>{footer}</Footer>}
      </Content>
    </Component>
  );
};
