import { Box, BoxProps } from '@chakra-ui/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled/macro';

type User = { username: string; first_name?: string; last_name?: string };
type Props = BoxProps & { longCredit?: boolean; showOnHover?: boolean; user: User };

function getDisplayName(user: User) {
  if (user.first_name || user.last_name) {
    return `${user.first_name} ${user.last_name}`.trim();
  } else {
    return user.username;
  }
}

const Wrapper = styled(Box, { shouldForwardProp: isPropValid })`
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0 0 5px 0;
  bottom: 1px;
  color: white;
  font-size: 8px;
  padding: 2px 4px;
  position: absolute;
  right: 1px;
  text-align: right;
  ${(p) => (p.showOnHover ? { visibility: 'hidden' } : {})};

  a {
    text-decoration: underline;
  }
`;

export const Credit: React.FC<Props> = ({ longCredit, showOnHover, user }) => (
  <Wrapper className="credit" showOnHover={showOnHover}>
    {longCredit && 'Photo by '}
    <a href={`https://unsplash.com/@${user.username}?utm_source=sliides&utm_medium=referral`} target="blank">
      {getDisplayName(user)}
    </a>
    {longCredit && (
      <>
        {' '}
        on{' '}
        <a href="https://unsplash.com?utm_source=sliides&utm_medium=referral" target="blank">
          Unsplash
        </a>
      </>
    )}
  </Wrapper>
);
