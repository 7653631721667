import {
  AccordionItem,
  AccordionPanel,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  VStack,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { BiExpand } from 'react-icons/bi';
import { PanelButton } from './PanelButton';

type Props = { height?: string; label: string; onChange(d: Dimension): void; width?: string };

export const DimensionsPanel: React.FC<Props> = ({ height, label = 'Dimensions', onChange, width }) => {
  // eslint-disable-next-line
  const change = useCallback(
    debounce((value: string) => onChange({ width: `${value}%` }), 200),
    [onChange],
  );

  const numericValue = width?.split('%')[0];

  return (
    <AccordionItem>
      <PanelButton icon={BiExpand} label={label} value={`w: ${width}`} />
      <AccordionPanel>
        <VStack spacing={2}>
          <Flex justifyContent="space-between" width="100%">
            <InputGroup>
              <InputLeftAddon children="Width" />
              <Input
                defaultValue={numericValue}
                max={100}
                min={0}
                onChange={(e) => change(e.currentTarget.value)}
                placeholder="80"
                textAlign="right"
                type="number"
              />
              <InputRightAddon children="%" />
            </InputGroup>
          </Flex>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};
