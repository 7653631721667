import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { Button } from 'components/Button/Button';
import React, { useCallback, useState } from 'react';
import { BiImage as ImageIcon, BiSearch, BiXCircle } from 'react-icons/bi';
import { createApi } from 'unsplash-js';
import { diapoOverrides } from 'utils/theme';
import { Credit } from '../Credit/Credit';
import { SlideThumb } from '../SlideThumb/SlideThumb';

const unsplash = createApi({
  apiUrl: 'https://www.sliides.io/.netlify/functions/unsplash',
});

const parsePhotos = async (query: string) => {
  const response = await unsplash.search.getPhotos({ query, perPage: 6 });

  return response.response?.results;
};

type Props = {
  isOpen?: boolean;
  onChange(url: string, username?: string): void;
  onClose?(): void;
  url?: string;
};

const ThumbWrapper = styled(Flex)`
  &:hover .credit {
    visibility: visible;
  }
`;

const FakeSlide: React.FC<{ onClick(): void; photo: any; src: string }> = ({ onClick, photo: { user }, src }) => (
  <Box float="left" margin="1%" width="calc(94% / 3)">
    <SlideThumb onClick={onClick}>
      <ThumbWrapper alignItems="center" height="100px" justifyContent="center">
        <Image src={src} />
        <Credit className="credit" showOnHover user={user} />
      </ThumbWrapper>
    </SlideThumb>
  </Box>
);

export const ImageModal: React.FC<Props> = ({ isOpen = true, onChange, onClose = () => {}, url: urlProp }) => {
  const [cachedString, setCachedString] = useState('');
  const [isLoading, { on, off }] = useBoolean();
  const [photos, setPhotos] = useState<any[]>();
  const [searchString, setSearchString] = useState('');
  const [urlString, setUrlString] = useState(urlProp);

  const toast = useToast();

  const change = useCallback(
    (url: string, id?: string, username?: string) => {
      onChange(url, username);
      if (id) {
        /**
         * Per Unsplash API Guidelines, photo must be tracked when it is selected:
         * https://help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
         */
        unsplash.photos.get({ photoId: id });
      }
      onClose();
    },
    [onChange, onClose],
  );

  const search = useCallback(
    (query: string) => {
      const loadPhotos = async () => {
        on();
        try {
          const photoList = await parsePhotos(query);
          setCachedString(query);
          setPhotos(photoList || []);
        } catch (e: unknown) {
          toast({ description: (e as Error).message, title: 'Error', status: 'error', variant: 'subtle' });
        }
        setTimeout(off, 200);
      };
      if (cachedString !== query) {
        loadPhotos();
      }
    },
    [cachedString, on, off, toast],
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxWidth="468px"
          style={{ '--border-color': 'var(--chakra-colors-secondary-200)' } as React.CSSProperties}
        >
          <ModalCloseButton alignSelf="flex-start" />
          <ModalHeader fontSize="md" textAlign="center">
            Select Image
          </ModalHeader>
          <Tabs align="center">
            <ModalBody padding="0">
              <TabList borderBottomWidth="1px" height="60px">
                <Tab>URL</Tab>
                <Tab>Search</Tab>
              </TabList>
            </ModalBody>
            <TabPanels>
              <TabPanel padding={0}>
                <ModalBody p={4}>
                  <FormControl>
                    <FormLabel htmlFor="imageUrl">Image URL</FormLabel>
                    <Input
                      autoFocus
                      onChange={(e) => setUrlString(e.currentTarget.value)}
                      id="imageUrl"
                      placeholder="https://"
                      type="text"
                      value={urlString}
                    />
                  </FormControl>
                </ModalBody>
                {/* TODO: use src/components/Modal and this shouldn't be necessary */}
                <ModalFooter {...diapoOverrides.components.Modal.baseStyle.footer}>
                  <HStack>
                    <Button bgColor="white" color="text.400" _hover={{ color: 'text.600' }} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button disabled={!urlString} onClick={() => urlString && change(urlString)}>
                      Save
                    </Button>
                  </HStack>
                </ModalFooter>
              </TabPanel>
              <TabPanel padding={0}>
                <ModalBody borderBottom="1px solid var(--border-color)" padding="20px">
                  <InputGroup>
                    <Input
                      onChange={(e) => setSearchString(e.currentTarget.value)}
                      onBlur={(e) => search(e.currentTarget.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') search(e.currentTarget.value);
                      }}
                      placeholder="Search photos via Unsplash"
                      value={searchString}
                    />
                    {searchString ? (
                      <InputRightElement onClick={() => setSearchString('')} children={<BiXCircle />} />
                    ) : (
                      <InputRightElement children={<BiSearch color="green.500" />} />
                    )}
                  </InputGroup>
                </ModalBody>
                <ModalBody padding="20px">
                  <Box>
                    {isLoading ? (
                      <Spinner />
                    ) : photos?.length ? (
                      photos?.map((photo) => (
                        <FakeSlide
                          key={photo.id}
                          src={`${photo.urls.raw}&fit=crop&w=244&h=200`}
                          onClick={() => change(photo.urls.full, photo.id, photo.user.username)}
                          photo={photo}
                        />
                      ))
                    ) : (
                      <Box color="text.300" padding="30px 0">
                        <ImageIcon size="64px" />
                        Browse Images
                      </Box>
                    )}
                  </Box>
                </ModalBody>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalContent>
      </Modal>
    </>
  );
};
