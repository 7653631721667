import { Box, Grid, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { colors, getColorWithIntensity, getContrastColor } from '../../utils/colors';
import { BiCheck as CheckIcon } from 'react-icons/bi';

type Props = {
  color?: string;
  components?: { Wrapper: React.ComponentType<any> };
  gap?: string;
  onChange(color: string): void;
};

const ColorCell = styled(Box, { shouldForwardProp: (p) => p !== 'isActive' })<{ isActive?: boolean }>`
  border-radius: 2px;
  ${(p) => (p.isActive ? 'box-shadow: 0 0 0 1px white, 0 0 0 3px currentColor; pointer-events: none;' : '')}
  cursor: pointer;
  display: flex;
  height: 100%;
  min-height: 10px;
  min-width: 10px;
  padding-bottom: 100%;

  position: relative;
  width: 100%;

  &:hover {
    box-shadow: 0 0 0 1px white, 0 0 0 2px currentColor;
    opacity: 0.75;
  }
`;

export const ColorGrid: React.FC<Props> = ({ components = {}, color: colorProp, gap = '8px', onChange }) => {
  const Wrapper = components.Wrapper || Box;
  return (
    <Grid gridGap={gap} gridTemplateColumns="repeat(10, 1fr)" width="100%">
      {colors.map((color) => (
        <Wrapper key={color} onClick={() => onChange(color)} padding="0">
          <ColorCell bgColor={color} color={getColorWithIntensity(color, 400, '.')} isActive={color === colorProp}>
            {color === colorProp && (
              <Icon as={CheckIcon} color={getContrastColor(color, '.', true)} height="100%" position="absolute" />
            )}
          </ColorCell>
        </Wrapper>
      ))}
    </Grid>
  );
};
