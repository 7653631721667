import { Container, Grid } from '@chakra-ui/react';
import { BlockList } from 'components/BlockList/BlockList';
import { Section } from 'components/Section/Section';
import { Slide } from 'components/Slide/Slide';
import { SlideContext } from 'contexts/SlideContext';
import React, { useContext } from 'react';
import { log } from 'utils/logger';

type Props = {
  /**
   * If true, this slide is in a thumbnail
   */
  isThumbnail?: boolean;
  /**
   * The slide to display. If not provided, the current slide will be used.
   * Useful in the DeckDrawer
   */
  slide?: Slide;
};

export const BasicSlide: React.FC<Props> = ({ isThumbnail = false, slide: slideProp }) => {
  const { currentSlide } = useContext(SlideContext);

  const { id, sections = [], ...slide } = slideProp || currentSlide;

  if (!slideProp) {
    // Only log for the main slide
    log('BasicSlide.render sections:', sections);
  }

  return (
    <Slide isThumbnail={isThumbnail} slideId={id} {...slide}>
      {sections.map((main, idx) => (
        <Section
          id={`${id}-${idx}`}
          justifyContent="center"
          key={`${id}-${idx}`}
          section={main}
          sectionKey={0}
          slideId={id}
        >
          <Container maxWidth={main.meta?.maxWidth || '80%'}>
            <Grid gap="1rem" fontSize="xl">
              {main.blocks ? (
                <BlockList blocks={main.blocks} sectionId={main.id} sectionIdx={idx} slideId={id} />
              ) : (
                'No content'
              )}
            </Grid>
          </Container>
        </Section>
      ))}
    </Slide>
  );
};
