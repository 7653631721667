import {
  HStack,
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { map } from 'lodash';
import React from 'react';
import { kbShortcuts } from 'utils/keyboard';

export const KeyboardShortcutModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  useKeyboardShortcut('help', onOpen);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxWidth="468px"
        style={{ '--border-color': 'var(--chakra-colors-secondary-200)' } as React.CSSProperties}
      >
        <ModalCloseButton alignSelf="flex-start" />
        <ModalHeader borderBottom="1px solid var(--border-color)" fontSize="md" textAlign="center">
          Keyboard Shortcuts
        </ModalHeader>
        <ModalBody>
          <Table variant="simple" size="sm">
            <TableCaption placement="top">
              You can use these keyboard shortcuts to perform actions across the site without using your mouse to
              navigate.
            </TableCaption>
            <Thead>
              <Tr>
                <Th>Description</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {map(kbShortcuts, (s) => (
                <Tr key={s.description}>
                  <Td>{s.description}</Td>
                  <Td>
                    <HStack>
                      {s.shortcutKeys.map((k) => (
                        <Kbd key={k}>{k}</Kbd>
                      ))}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
