import { Flex, FlexProps } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { Credit } from 'components/Credit/Credit';
import { SectionPanel } from 'components/Section/subcomponents/SectionPanel';
import { useDeckContext } from 'contexts/DeckContext';
import { useFullScreenContext } from 'contexts/FullScreenContext';
import { useToolbar } from 'contexts/ToolbarContext';
import { useEffect } from 'react';
import { getColor, getContrastColor } from 'utils/colors';

type Props = FlexProps & { id: string; section: Section; sectionKey: number; slideId: string };

const SectionWrapper = styled(Flex, {
  shouldForwardProp: (p) => p !== 'baseColor' && p !== 'isSelected' && p !== 'contrastColor',
})`
  --base-color: ${(p) => p.baseColor};
  --border-width: 4px;
  --contrast-color: var(--chakra-colors-${(p) => p.contrastColor});
  align-items: center;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 0 rgba(250, 250, 250, 0.5);
  cursor: pointer;
  height: 100%;
  justify-content: center;
  position: relative;
  transition: 0.4s ease box-shadow;
  width: 100%;

  .content-button,
  .toolbar {
    opacity: 0;
    transition: 0.4s ease opacity;
  }

  ${(p) =>
    p.disabled
      ? 'cursor: default;'
      : `  &:hover {
        box-shadow: inset 0 0 0 var(--border-width) rgba(200, 200, 200, 0.5);
    
        .content-button,
        .toolbar {
          opacity: 1;
        }
      }`}

  ${(p) =>
    p.isSelected
      ? `
    box-shadow: inset 0 0 0 var(--border-width) var(--chakra-colors-brand-500) !important;`
      : ''}
`;

export const Section: React.FC<Props> = ({ id, children, section, sectionKey, slideId, ...props }) => {
  const isThumbnail = id.includes('thumbnail'); // FIXME better way?
  const { activeToolbarKey, addToolbar, setActiveToolbar } = useToolbar();
  const { isFullScreen } = useFullScreenContext();
  const { readonly } = useDeckContext();

  // log('Section.render ', section.id, 'meta:', section.meta);

  useEffect(() => {
    !isThumbnail && addToolbar(id, <SectionPanel section={section} />);
  }, [section]); // eslint-disable-line

  return (
    <SectionWrapper
      baseColor={getColor(section.meta?.bgColor || 'gray.500')}
      bgColor={section.meta?.bgColor}
      bgImage={`url(${section.meta?.bgImage}&fit=crop&max-h=2400&max-w=2400)`}
      color={section.meta?.fontColor}
      contrastColor={getContrastColor(section.meta?.bgColor)}
      fontFamily={section.meta?.fontFamily}
      disabled={isFullScreen || readonly}
      id={`${slideId}-${id}`}
      isSelected={activeToolbarKey === id}
      onClick={() => setActiveToolbar(id)}
      // @ts-ignore
      style={{ '--sliide-text-font-family': section.meta?.fontFamily }}
    >
      {section.meta?.bgImageCredit && (
        <Credit longCredit position="absolute" user={{ username: section.meta.bgImageCredit }} />
      )}
      {children}
    </SectionWrapper>
  );
};

export type SectionProps = Props;
