import React, { useCallback, useContext } from 'react';
import {
  BiPlus as AddIcon,
  BiCodeBlock as CodeIcon,
  BiFont as TextIcon,
  BiImage as ImageIcon,
  BiHorizontalCenter as SectionIcon,
} from 'react-icons/bi';
import { SlideContext } from '../../contexts/SlideContext';

import { IconBar } from '../IconBar/IconBar';
import { IconBarButton } from '../IconBar/IconBarButton';

type Props = {
  nextBlockIdx: number;
  sectionId: string;
};

export const AddContentIconBar: React.FC<Props> = ({ nextBlockIdx, sectionId }) => {
  const { updateBlock, updateSection, currentSlide, slideId } = useContext(SlideContext);

  const onClick = useCallback(
    ({ value, type }: { value: string; type: string }) => {
      if (type === 'section') {
        updateSection({
          section: { blocks: [{ order: 0, type: 'text', value: 'Add a section' }] as Block[], slideId },
        });
      } else {
        updateBlock({
          block: { order: nextBlockIdx, sectionId, type, value },
        });
      }
    },
    [nextBlockIdx, sectionId, slideId, updateBlock, updateSection],
  );

  return (
    <IconBar
      buttons={[
        <IconBarButton
          label="Add text"
          icon={TextIcon}
          onClick={() => onClick({ value: 'Click text to edit.', type: 'text' })}
        />,
        <IconBarButton
          label="Add image"
          icon={ImageIcon}
          onClick={() => onClick({ value: 'http://placeimg.com/640/360/any', type: 'image' })}
        />,
        <IconBarButton
          label="Add code block"
          icon={CodeIcon}
          onClick={() => onClick({ value: 'Click text to edit.', type: 'code' })}
        />,
        ...(currentSlide.sections.length > 1
          ? []
          : [
              <IconBarButton
                label="Split Slide"
                icon={SectionIcon}
                onClick={() => onClick({ value: 'Click text to edit.', type: 'section' })}
              />,
            ]),
      ]}
      label="Add content"
      toggleIcon={AddIcon}
    />
  );
};
