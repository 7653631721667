import { ColorPanel } from 'components/PropertiesPanel/subcomponents/ColorPanel';
import { DimensionsPanel } from 'components/PropertiesPanel/subcomponents/DimensionsPanel';
import { ImagePanel } from 'components/PropertiesPanel/subcomponents/ImagePanel';
import { PanelTitle } from 'components/PropertiesPanel/subcomponents/PanelTitle';
import { TextPanel } from 'components/PropertiesPanel/subcomponents/TextPanel';
import { useSlideContext } from 'contexts/SlideContext';

type Props = { section: Section };

export const SectionPanel: React.FC<Props> = ({ section }) => {
  const { updateSection } = useSlideContext();

  const updateColor = (bgColor: string) =>
    updateSection({
      section: { ...section, meta: { ...section.meta, bgColor } },
    });
  const updateFont = (meta: TextMeta) => updateSection({ section: { ...section, meta } });

  const updateImage = (bgImage?: string, bgImageCredit?: string) =>
    updateSection({ section: { ...section, meta: { ...section.meta, bgImage, bgImageCredit } } });
  const updateWidth = ({ width }: Dimension) =>
    updateSection({ section: { ...section, meta: { ...section.meta, maxWidth: width } } });

  return (
    <>
      <PanelTitle>Section Properties</PanelTitle>
      <ColorPanel
        color={section.meta?.bgColor}
        label="Background Color"
        onChange={updateColor}
        warning={section.meta?.bgColor && section.meta?.bgImage && 'May not be visible behind image'}
      />
      <ImagePanel label="Background Image" onChange={updateImage} url={section.meta?.bgImage} />
      <ColorPanel
        color={section.meta?.fontColor}
        label="Font Color"
        onChange={(c) => updateFont({ ...section.meta, fontColor: c })}
      />
      <TextPanel onChange={updateFont} meta={section.meta} />
      <DimensionsPanel label="Width" onChange={updateWidth} width={section.meta?.maxWidth} />
    </>
  );
};

export type SectionProps = Props;
