import { CloseButton } from '@chakra-ui/react';
import { PropertiesPanel } from 'components/PropertiesPanel/PropertiesPanel';
import { useToolbar } from 'contexts/ToolbarContext';
import { motion } from 'framer-motion';
import styles from './SlideDrawer.module.scss';

export const SlideDrawer = () => {
  const { activeToolbar, activeToolbarKey, setActiveToolbar } = useToolbar();

  return (
    <motion.div
      animate={!!activeToolbarKey ? 'open' : 'closed'}
      className={styles.drawer}
      variants={{
        open: { x: 0 },
        closed: { x: '100%' },
      }}
    >
      <CloseButton
        color="text.300"
        isDisabled={!activeToolbarKey} // disable to remove from tabbing when not on screen
        onClick={() => setActiveToolbar(undefined)}
        position="absolute"
        right="10px"
        size="sm"
        top="7px"
      />
      <PropertiesPanel>{activeToolbar}</PropertiesPanel>
    </motion.div>
  );
};
