import { Spinner, useBoolean, useDisclosure } from '@chakra-ui/react';
import { DeckSettingsModal } from 'components/DeckSettingsModal/DeckSettingsModal';
import { useNotificationContext } from 'contexts/NotificationContext';
import { useDeckList } from 'hooks/useDeckList';
import { useIdentity } from 'hooks/useIdentity';
import { merge } from 'lodash';
import React, { createContext, Suspense, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { defaultSlideMap } from 'tmp.slides';
import { getLatestDeck } from 'utils/decks';
import { log } from 'utils/logger';

type SaveSlidesConfig = (slides?: Slide[], deck?: { id?: string; title?: string }) => void;

type DeckContextType = {
  activeDeck?: Deck;
  createDeck(): void;
  deleteDeck(id: string): void;
  deleteSlide(id: string): void;
  decks: Deck[];
  loading: boolean | string;
  openSettings?(): void;
  publishDeck(): void;
  readonly?: boolean;
  refetch(): void;
  refetchDecks(decks: Deck[]): Promise<Deck[] | undefined>;
  saveDeck(deck: Deck): void;
  saveSlide(slide: Slide): void;
  saveSlides: SaveSlidesConfig;
};

export const DeckContext = createContext<DeckContextType>({} as DeckContextType);

/**
 * Handles loading the users decks.
 */
export const DeckProvider: React.FC = ({ children }) => {
  const { mutate: refetch } = useSWRConfig();
  const { user } = useIdentity();
  const [creating, { on: setCreateMode, off: unsetCreateMode }] = useBoolean(); // FIXME Don't like this :)
  const [loading, { on: startLoading, off: stopLoading }] = useBoolean();
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { notify } = useNotificationContext();
  const { deckId } = useParams();
  const [decks, refetchDecks] = useDeckList({ user });
  const navigate = useNavigate();

  const startLoader = useCallback(
    (msg?: string) => {
      setLoadingMessage(msg);
      startLoading();
    },
    [startLoading],
  );

  const stopLoader = useCallback(() => {
    setLoadingMessage(undefined);
    stopLoading();
  }, [stopLoading]);

  console.log('DeckContext RERENDER');
  const currentDeck = useMemo(() => {
    let selectedDeck;
    log('DeckContext.currentDeck loaded decks', decks.length, 'looking for', deckId);
    if (deckId) {
      selectedDeck = decks.find((d) => d.id === deckId || d.slug === deckId);
      log('DeckContext.currentDeck loading selected deck:', selectedDeck);
    }
    if (user && !selectedDeck) {
      const latestDeck = getLatestDeck(decks);
      log('DeckContext.currentDeck no selected deck, navigating to latest deck:', latestDeck);
      navigate(`/u/${latestDeck?.user.username}/d/${latestDeck?.slug}`);
    }
    return selectedDeck || decks[0];
  }, [deckId, decks, navigate, user]);

  const saveDeck = useCallback(
    async ({ id: deckId = currentDeck?.id, meta = {}, title = currentDeck?.title } = {}) => {
      if (currentDeck?.readonly || !user) {
        log();
        return;
      }
      const deckConfig = {
        id: deckId,
        meta: merge(currentDeck?.meta || {}, meta),
        title,
      };
      startLoader('Saving Deck...');
      if (user) {
        const result = await fetch('/.netlify/functions/saveDeck', {
          body: JSON.stringify({ deck: deckConfig }),
          method: 'POST',
        });
        const savedDeck = await result.json();
        log('DeckContext.savedDeck', savedDeck);
        const newDecks = await refetchDecks(
          decks.map((d) => (d.id === savedDeck.id ? { ...currentDeck, ...savedDeck } : d)),
        );
        console.log('DeckContext.refetchedDecks', newDecks);
      }
      stopLoader();
      notify({ id: 'deck-save-toast', level: 'success', message: 'Deck Saved', type: 'toast' });
    },
    [currentDeck, decks, notify, refetchDecks, startLoader, stopLoader, user],
  );

  const saveSlides = useCallback(
    async (slides, { id: deckId, meta = {}, title = currentDeck?.title } = {}) => {
      if (currentDeck?.readonly) return;
      if (user) {
        const deckConfig = {
          id: deckId,
          meta: merge(currentDeck?.meta || {}, meta),
          slides,
          title,
          userId: user?.id,
        };
        startLoader();
        const result = await fetch('/.netlify/functions/saveDeck', {
          body: JSON.stringify({ deck: deckConfig }),
          method: 'POST',
        });
        const savedDeck = await result.json();
        log('Saved Deck:', savedDeck);
        refetchDecks(decks.map((d) => (d.id === savedDeck.id ? savedDeck : d)));
        stopLoader();
        navigate(`/u/${savedDeck?.user.username}/d/${savedDeck.slug}`);
        notify({ id: 'deck-save-toast', level: 'success', message: 'Slides Saved', type: 'toast' });
      }
    },
    [
      currentDeck?.title,
      currentDeck?.readonly,
      currentDeck?.meta,
      user,
      startLoader,
      refetchDecks,
      decks,
      stopLoader,
      navigate,
      notify,
    ],
  );

  const createDeck = useCallback(() => {
    setCreateMode();
    onOpen();
  }, [onOpen, setCreateMode]);

  const deleteDeck = useCallback(
    async (deckId: string) => {
      try {
        await fetch('/.netlify/functions/deleteDeck', {
          method: 'DELETE',
          body: JSON.stringify({ deckId }),
        });
        // Redirect to the most recent deck
        const newDeckList = decks.filter((d) => d.id !== deckId);
        refetchDecks(newDeckList);
        const latestDeck = getLatestDeck(newDeckList);
        navigate(`/u/${latestDeck?.user?.username}/d/${latestDeck?.id}`);
        // Notify of success
        notify({ id: 'deck-delete-toast', level: 'success', message: 'Deck Deleted', type: 'toast' });
      } catch (e) {
        console.log(e);
      }
    },
    [decks, navigate, notify, refetchDecks],
  );

  const publishDeck = useCallback(async () => {
    if (currentDeck && user) {
      log('Publishing Deck:', currentDeck.slides.length, 'slides');
      await fetch('/.netlify/functions/publishDeck', {
        body: JSON.stringify({ deck: currentDeck }),
        method: 'POST',
      });
    }
  }, [user, currentDeck]);

  const saveSlide = useCallback(
    async (slide) => {
      if (!currentDeck || currentDeck?.readonly) return;
      const slideConfig = {
        ...slide,
        id: slide.id,
        userId: user?.id,
      };
      startLoader('Saving slide...');
      if (user) {
        const result = await fetch('/.netlify/functions/saveSlide', {
          body: JSON.stringify({ slide: { deckId: currentDeck.id, ...slideConfig } }),
          method: 'POST',
        });
        const savedSlide = await result.json();
        log('Saved Slide:', savedSlide);
        // merge this slide into the current deck
        const newDeck = {
          ...currentDeck,
          slides: slide.deckId
            ? currentDeck.slides.map((s) => (s.id === savedSlide.id ? savedSlide : s))
            : [...currentDeck.slides, savedSlide],
        };
        await refetchDecks(decks.map((d) => (d.id === currentDeck.id ? newDeck : d)));
      }
      stopLoader();
      notify({ id: 'deck-save-toast', level: 'success', message: 'Slide Saved', type: 'toast' });
    },
    [decks, startLoader, stopLoader, notify, user, currentDeck, refetchDecks],
  );

  const deleteSlide = useCallback(
    async (slideId) => {
      if (currentDeck?.slides && currentDeck.slides.length > 1) {
        try {
          await fetch('/.netlify/functions/deleteSlide', {
            body: JSON.stringify({ slideId }),
            method: 'DELETE',
          });
          refetchDecks(
            decks.map((d) =>
              d.id === currentDeck.id
                ? { ...currentDeck, slides: currentDeck.slides.filter((s) => s.id !== slideId) }
                : d,
            ),
          );
        } catch (e) {
          notify({
            type: 'toast',
            level: 'error',
            id: 'remove-slide-notice',
            message: 'Error deleting slide' + e,
          });
        }
      } else {
        notify({
          type: 'toast',
          level: 'warning',
          id: 'remove-slide-notice',
          message: 'Can not delete if there is only one slide',
        });
      }
    },
    [currentDeck, decks, notify, refetchDecks],
  );

  return (
    <DeckContext.Provider
      value={{
        activeDeck: currentDeck,
        createDeck,
        decks,
        deleteDeck,
        deleteSlide,
        loading: loadingMessage || loading,
        openSettings: onOpen,
        publishDeck,
        readonly: !user || currentDeck?.userId !== user.id,
        refetch: () => refetch('/.netlify/functions/getDeck'),
        refetchDecks,
        saveDeck,
        saveSlide,
        saveSlides,
      }}
    >
      <Suspense
        fallback={
          <div>
            <Spinner /> DeckContext loading...
          </div>
        }
      >
        {children}
        {
          <DeckSettingsModal
            deck={creating ? undefined : currentDeck}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              unsetCreateMode();
            }}
            onDelete={user && currentDeck ? () => deleteDeck(currentDeck?.id) : undefined}
            onSave={async ({ title }) => {
              if (creating) {
                log('DeckContext.DeckSettingsModal.onSave creating:', title);
                await saveSlides([{ sections: defaultSlideMap.basic(), type: 'basic' }], { title });
                navigate(`/u/${currentDeck.user?.username}/d/${currentDeck?.id}`);
              } else {
                log('DeckContext.DeckSettingsModal.onSave updating:', title);
                await saveDeck({ id: currentDeck?.id, title });
              }
            }}
          />
        }
      </Suspense>
    </DeckContext.Provider>
  );
};

export const useDeckContext = () => useContext(DeckContext);
