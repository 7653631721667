import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import React from 'react';

type WindowProps = {
  bgColor?: string;
  onClick?(e: React.SyntheticEvent): void;
  title?: React.ReactNode;
};

const StyledWindow = styled(motion.div, { shouldForwardProp: isPropValid })<{ bgColor: string }>`
  background-color: ${(p) => p.bgColor};
  border-radius: 0.4em;
  box-shadow: var(--sii-boxshadow-elevation-high);
  color: white;
  margin: 0 0 20px;
  overflow: hidden;

  &:hover {
    box-shadow: var(--sii-boxshadow-elevation-high), 0px 0px 0px 3px var(--contrast-color);
  }
`;

const MenuBar = styled.nav`
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 20px;

  & > div {
    display: flex;
    gap: 8px;
  }
`;

const transition = { delay: 0.4, duration: 0.6 };

const MotionButton = (props: any) => (
  <motion.button
    variants={{
      blockVisible: {
        ease: [0, 1, 1.2, 1],
        scale: 1,
        transition: { ...transition, delay: transition.delay + transition.duration },
      },
    }}
    initial={{ scale: 0 }}
    {...props}
  />
);

const Button = styled(MotionButton, { shouldForwardProp: isPropValid })<{ go?: boolean; stop?: boolean }>`
  --button-size: 14px;
  --green: #00ca4e;
  --red: #ff605c;
  --yellow: #ffbd44;
  background: ${(p) => (p.go ? 'var(--green)' : p.stop ? 'var(--red)' : 'var(--yellow)')};
  border: none;
  border-radius: var(--button-size);
  height: var(--button-size);
  width: var(--button-size);
`;

export const Window: React.FC<WindowProps> = ({ bgColor = '#263238', children, onClick = () => {}, title }) => (
  <StyledWindow
    bgColor={bgColor}
    onClick={onClick}
    initial={{ height: 0 }}
    variants={{ blockVisible: { height: 'auto', transition } }}
  >
    <div style={{ padding: '24px' }}>
      <MenuBar>
        <div style={{ position: 'absolute' }}>
          <Button stop />
          <Button />
          <Button go />
        </div>
        {title && <div style={{ flex: 1, justifyContent: 'center' }}>{title}</div>}
      </MenuBar>
      {children}
    </div>
  </StyledWindow>
);
