import { cloneDeep } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useIdentityContext, User } from 'react-netlify-identity';
import { useNavigate } from 'react-router-dom';
import { log } from 'utils/logger';

export const useIdentity = () => {
  // List of functions available here: https://github.com/netlify-labs/react-netlify-identity#usage
  const { isLoggedIn, logoutUser, setUser, updateUser: updateUserOriginal, user } = useIdentityContext();
  const navigate = useNavigate();

  const updateUser = useCallback(
    async (d) => {
      const updatedUser = await updateUserOriginal(d);
      // FIXME is it necessary to call both of these?
      setUser(cloneDeep(updatedUser));
    },
    [setUser, updateUserOriginal],
  );

  useEffect(() => {
    if (!window.com.sii.identityInitialized) {
      window.netlifyIdentity.on('login', async (user: User) => {
        const result = await fetch('/.netlify/functions/saveUser', {
          body: JSON.stringify(user),
          method: 'POST',
        });
        const userData = await result.json();
        log('useIdentity.init logged in as', userData);
        navigate(`/u/${userData.username}`);
      });

      window.netlifyIdentity.on('signup', async (user: User) => {
        await fetch('/.netlify/functions/saveUser', {
          body: JSON.stringify(user),
          method: 'POST',
        });
        setUser(user);
      });

      window.netlifyIdentity.on('logout', () => {
        setUser(undefined);
        log('useIdentity.logout complete');
        // window.open('/', '_self');
      });
      window.com.sii.identityInitialized = true;
    }
    // eslint-disable-next-line
  }, []);

  const login = () => window.netlifyIdentity.open('login');
  const signup = () => window.netlifyIdentity.open('signup');
  const logout = () => {
    logoutUser();
    log('useIdentity.logout started');
    window.netlifyIdentity.logout();
  };

  return {
    isLoggedIn,
    login,
    logout,
    signup,
    updateUser,
    user,
  };
};
