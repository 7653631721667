import { Alert } from 'components/Alert/Alert';
import { Dialog } from 'components/Dialog/Dialog';

export type NotificationLevel = 'info' | 'error' | 'success' | 'warning';
type NotificationType = 'banner' | 'dialog' | 'toast';

type Props = {
  header?: React.ReactNode;
  id?: string;
  level?: NotificationLevel;
  message: React.ReactNode;
  onClose?(confirm?: boolean): void;
  type?: NotificationType;
};

export const Notification: React.FC<Props> = ({
  message,
  header,
  id,
  level = 'info',
  onClose = () => {},
  type = 'dialog',
}) => {
  const Component = type === 'dialog' ? Dialog : Alert;
  return <Component id={id} isOpen content={message} header={header} onClose={onClose} variant={level} />;
};

export type NotificationProps = Props;
