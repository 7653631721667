import { ButtonGroup, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { BiCog as CogIcon } from 'react-icons/bi';

type Props = {
  buttons: React.ReactNode[];
  colorScheme?: string;
  label: string;
  openLeft?: boolean;
  toggleIcon?: React.ElementType;
};

export const IconBar: React.FC<Props> = ({
  buttons,
  colorScheme,
  label,
  openLeft,
  toggleIcon: ToggleIcon = CogIcon,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const toggleEl = (
    <Tooltip label={label}>
      <IconButton aria-label={label} colorScheme={isOpen ? 'brand' : 'gray'} icon={<ToggleIcon />} onClick={onToggle} />
    </Tooltip>
  );

  return (
    <ButtonGroup className="toolbar" color="black">
      {!openLeft && toggleEl}
      {isOpen && (
        <ButtonGroup colorScheme={colorScheme}>
          {buttons.map((b, key) => (
            <React.Fragment key={key}>{b}</React.Fragment>
          ))}
        </ButtonGroup>
      )}
      {openLeft && toggleEl}
    </ButtonGroup>
  );
};
