import { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styled from '@emotion/styled/macro';

const DraggableWrapper = styled.div<{ dragging: boolean }>`
  cursor: move;
  opacity: ${({ dragging }) => (dragging ? 0 : 1)};
`;

type DragItem = {
  index: number;
  id: string;
  type: string;
};

type Props = {
  accept: string;
  id: string;
  index: number;
  onDrag: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd?(): void;
  // This sectionkey will be used to determine which items can interact with others when dragging.
  // FIXME: Does not belong in generic Draggable
  sectionKey?: number;
};

export const Draggable: React.FC<Props> = ({
  accept,
  children,
  id,
  index,
  onDrag,
  onDragEnd = () => {},
  sectionKey,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onDrag(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: () => ({ id, index }),
    end: onDragEnd,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <DraggableWrapper dragging={isDragging} id={id} ref={ref} data-handler-id={handlerId}>
      {children}
    </DraggableWrapper>
  );
};
