import {
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Button,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react';
import { ColorGrid } from 'components/ColorGrid/ColorGrid';
import { useCallback, useState } from 'react';
import { BiMessageRoundedError, BiPalette as ColorIcon } from 'react-icons/bi';
import { BsFillCircleFill as CircleIcon } from 'react-icons/bs';
import { IconType } from 'react-icons/lib';
import { getRGBA } from 'utils/colors';
import { PanelButton } from './PanelButton';

type Props = {
  color?: string;
  icon?: IconType;
  label?: string;
  onChange(color?: string): void;
  hasOpacity?: boolean;
  warning?: string;
};

export const ColorPanel: React.FC<Props> = ({
  color = '',
  icon = ColorIcon,
  label = 'Color',
  onChange,
  hasOpacity,
  warning,
}) => {
  const [opacity, setOpacity] = useState(color[0] === '#' && color.length === 9 ? parseInt(color.slice(7), 16) : 255);
  const changeOpacity = useCallback(
    (o) => {
      setOpacity(o);
      onChange(getRGBA(color || 'gray', o));
    },
    [color, onChange],
  );
  return (
    <AccordionItem>
      <PanelButton icon={icon} label={label} value={color && <Icon as={CircleIcon} color={color} />} />
      <AccordionPanel>
        {warning && (
          <Alert
            fontSize="12px"
            mb="20px"
            ml="calc(-1 * var(--chakra-space-4))"
            status="warning"
            variant="subtle"
            width="calc(100% + 2 * var(--chakra-space-4))"
          >
            <AlertIcon as={BiMessageRoundedError} color="yellow.500" /> {warning}
          </Alert>
        )}
        <VStack spacing={4}>
          <ColorGrid
            color={color}
            gap="4px"
            onChange={(c) => {
              hasOpacity ? onChange(getRGBA(c, opacity)) : onChange(c);
            }}
          />
          {hasOpacity && (
            <Slider
              colorScheme="brand"
              min={0}
              max={255}
              onChange={setOpacity}
              onChangeEnd={changeOpacity}
              step={1}
              value={opacity}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          )}
          <Button onClick={() => onChange('')} size="xs" variant="link">
            Clear
          </Button>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};
