import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useMemo } from 'react';

type Props = Omit<IconButtonProps, 'aria-label' | 'icon'> & {
  icon: React.ComponentType;
  label: string;
  showTooltip?: boolean;
};

export const IconBarButton: React.FC<Props> = ({
  className,
  color = 'text.700',
  colorScheme,
  icon: Icon,
  label,
  showTooltip = true,
  ...props
}) => {
  const button = useMemo(
    () => (
      <motion.div
        whileHover={{
          scale: 1.1,
          transition: { duration: 0.4 },
        }}
      >
        <IconButton
          aria-label={label}
          className={`${className} icon_bar_button-icon_button`}
          color={color}
          colorScheme={colorScheme || 'gray'}
          icon={<Icon />}
          {...props}
        />
      </motion.div>
    ),
    [Icon, className, color, colorScheme, label, props],
  );

  return showTooltip ? (
    <Tooltip
      className={`${className ? `${className}-tooltip` : ''} icon_bar_button-tooltip`}
      label={label}
      shouldWrapChildren={false}
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
};
