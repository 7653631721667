import { Box, BoxProps } from '@chakra-ui/react';

export const Logo: React.FC<BoxProps> = ({ height, onClick }) => (
  <Box
    alignItems="center"
    color="#724F91"
    display="flex"
    gridGap="10px"
    fontWeight={800}
    fontSize="24px"
    onClick={onClick}
  >
    {/* @ts-ignore */}
    <img src="/logo.svg" alt="Sliides" style={{ height }} />
  </Box>
);
