import { Alert as Component, CloseButton, HStack } from '@chakra-ui/react';
import { IconType } from 'react-icons';

type Props = {
  content: React.ReactNode;
  icon?: IconType;
  id?: string;
  onClose?(): void;
  variant?: string;
};

function getVariantGradient(variant: string | undefined): { backgroundImage: string; color: string } {
  return { backgroundImage: `var(--sii-gradient-${variant})`, color: variant === 'neutral' ? 'text.500' : 'white' };
}

export const Alert: React.FC<Props> = ({ content, icon: Icon, id, onClose, variant }) => {
  return (
    <Component
      fontSize="var(--chakra-fontSizes-xs)"
      fontWeight="600"
      id={id}
      justifyContent="center"
      variant={variant}
      {...getVariantGradient(variant)}
    >
      <HStack spacing={2}>
        {Icon && (
          <div>
            <Icon fontSize="1.8em" />
          </div>
        )}
        <div> {content}</div>
      </HStack>
      {onClose && <CloseButton onClick={onClose} position="absolute" right="10px" />}
    </Component>
  );
};
