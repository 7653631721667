import { useSlide } from 'api/slide';
import React, { createContext, useCallback, useContext } from 'react';
import { log } from 'utils/logger';
import { usePagination } from '../hooks/usePagination';
import { useDeckContext } from './DeckContext';
import { useNotificationContext } from './NotificationContext';

type updateBlockConfig = {
  block: Pick<Block, 'order' | 'sectionId' | 'type' | 'value'> & { id?: string };
};

type SlideContextType = {
  currentSlide: Slide;
  editMode: boolean;
  slideId: string;

  addSlide(config: SlideMeta): void;
  deleteBlock({ blockId, sectionIdx }: { blockId: string; sectionIdx: number }): void;
  updateBlock(config: updateBlockConfig): Promise<void>;
  updateSection(config: { section: Partial<Section> }): void;
};

// @ts-ignore
export const SlideContext = createContext<SlideContextType>({});

export const SlideProvider: React.FC = ({ children }) => {
  const { activeDeck, readonly, refetch, saveSlide } = useDeckContext();
  const { notify } = useNotificationContext();
  const [activeSlide] = usePagination();

  if (!activeDeck || activeSlide === undefined) throw new Error(`No active deck found. ${activeSlide}`);

  // FIXME: activeDeck should not be undefined by the time we get here
  const { data = [], mutate: refetchSlide } = useSlide({ deckId: activeDeck?.id || '', slideIdx: activeSlide });
  if (!data || !data.length) throw new Error(`No slide loaded from deck ${activeDeck?.title}`);
  const currentSlide = data[0];

  log('SlideProvider.render slide', currentSlide);

  const updateBlock = useCallback(
    async ({ block }: updateBlockConfig) => {
      const response = await fetch('/.netlify/functions/saveBlock', {
        method: 'POST',
        body: JSON.stringify({ block }),
      });

      const updatedBlock = await response.json();
      const sections = currentSlide.sections.map((s) =>
        s.id === block.sectionId
          ? {
              ...s,
              blocks: block.id
                ? s.blocks.map((b) => (b.id === updatedBlock ? { ...b } : b))
                : [...s.blocks, updatedBlock],
            }
          : s,
      );
      const changedSlide = {
        ...currentSlide,
        sections,
      };

      log('SlideProvider.updateBlock newSlide:', changedSlide);
      refetchSlide([changedSlide]);
      refetch();
      notify({ id: 'section-update-toast', level: 'success', message: 'Changes Saved', type: 'toast' });
    },
    [currentSlide, notify, refetch, refetchSlide],
  );

  const addSlide = useCallback(
    async (slideConfig: Slide) => {
      await saveSlide(slideConfig);
    },
    [saveSlide],
  );

  const deleteBlock = useCallback(
    async ({ blockId, sectionIdx }) => {
      try {
        await fetch('/.netlify/functions/deleteBlock', {
          method: 'POST',
          body: JSON.stringify({ blockId }),
        });
      } catch (e) {
        console.log(e);
      }
      refetchSlide([
        {
          ...currentSlide,
          sections: currentSlide.sections.map((s, idx) =>
            idx === sectionIdx ? { ...s, blocks: s.blocks.filter((b) => b.id !== blockId) } : s,
          ),
        },
      ]);
      refetch();
    },
    [currentSlide, refetch, refetchSlide],
  );

  const updateSection = useCallback(
    async ({ section }) => {
      const response = await fetch('/.netlify/functions/saveSection', {
        method: 'POST',
        body: JSON.stringify({ section }),
      });

      const updatedSection = await response.json();
      const changedSlide = {
        ...currentSlide,
        sections: currentSlide.sections.map((s) => (s.id === updatedSection.id ? updatedSection : s)),
      };

      refetchSlide([changedSlide]);
      refetch();
      notify({ id: 'section-update-toast', level: 'success', message: 'Changes Saved', type: 'toast' });
    },
    [currentSlide, notify, refetch, refetchSlide],
  );

  return (
    <SlideContext.Provider
      value={{
        addSlide,
        currentSlide,
        editMode: !readonly,
        deleteBlock,
        slideId: currentSlide.id,
        updateBlock,
        updateSection,
      }}
    >
      {children}
    </SlideContext.Provider>
  );
};

export const useSlideContext = () => useContext(SlideContext);
