import { Deck } from 'components/Deck/Deck';
import { DeckDrawer } from 'components/DeckDrawer/DeckDrawer';

export const DeckViewer = () => {
  return (
    <>
      <DeckDrawer />
      <Deck />
    </>
  );
};
