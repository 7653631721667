import { Button, ButtonGroup, IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useDeckContext } from 'contexts/DeckContext';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { usePagination } from 'hooks/usePagination';
import React from 'react';
import { BiLeftArrowAlt as LeftIcon, BiRightArrowAlt as RightIcon } from 'react-icons/bi';

export const topMenuStyle = `
  --border-color: var(--chakra-colors-text-100);
  border-color: var(--border-color);
  color: var(--chakra-colors-text-400);

  &:disabled {
    border-color: var(--border-color);
    color: var(--chakra-colors-text-100);
    opacity: 1;
  }
`;

const NavButton = styled(Button)(topMenuStyle);
const NavIconButton = styled(IconButton)(topMenuStyle);

type Props = { isDisabled: boolean };

export const SliderNavigation: React.FC<Props> = ({ isDisabled }) => {
  const { activeDeck: { slides = [] } = {} } = useDeckContext();
  const [activeSlide, { onNext, onPrev, goto }] = usePagination();

  useKeyboardShortcut('next', onNext);
  useKeyboardShortcut('prev', onPrev);
  useKeyboardShortcut('reset', () => goto(0));

  return (
    <ButtonGroup size="sm" isAttached variant="outline">
      <NavIconButton
        aria-label="Previous Slide"
        icon={<LeftIcon />}
        isDisabled={isDisabled || activeSlide === 0}
        onClick={onPrev}
      />
      <NavButton isDisabled={isDisabled} ml="-px" mr="-px">
        Slide {activeSlide + 1} of {slides.length}
      </NavButton>
      <NavIconButton
        aria-label="NextSlide"
        icon={<RightIcon />}
        isDisabled={isDisabled || slides.length === activeSlide + 1}
        onClick={onNext}
      />
    </ButtonGroup>
  );
};
