import useSWR from 'swr';
import { log } from 'utils/logger';

type UseSlideConfig = {
  deckId: string;
  slideIdx: number;
};

export const useSlide = ({ deckId, slideIdx }: UseSlideConfig) => {
  const { data = [], ...retVal } = useSWR<Slide[]>(`/.netlify/functions/getSlide?deckId=${deckId}&idx=${slideIdx}`, {
    suspense: true,
  });

  log('API.useSlide:', data);

  return { data, ...retVal };
};
