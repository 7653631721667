import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useCallback, useContext } from 'react';
import { SlideContext } from '../../contexts/SlideContext';
import { defaultSlideMap } from '../../tmp.slides';
import { SlideThumb } from '../SlideThumb/SlideThumb';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

const FakeSlide: React.FC<{ onClick(): void }> = ({ children, onClick }) => (
  <Box float="left" margin="1%" width="calc(94% / 3)">
    <SlideThumb onClick={onClick}>
      <Flex alignItems="center" height="100px" justifyContent="center">
        {children}
      </Flex>
    </SlideThumb>
  </Box>
);

export const AddSlideModal: React.FC<Props> = ({ isOpen, onClose, onSuccess }) => {
  const { addSlide } = useContext(SlideContext);

  const onAdd = useCallback(
    async (slide: SlideMeta) => {
      await addSlide(slide);
      onClose();
      onSuccess();
    },
    [addSlide, onClose, onSuccess],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="468px">
        <ModalCloseButton alignSelf="flex-start" />
        <ModalHeader borderBottom="1px solid var(--chakra-colors-secondary-100)" fontSize="md" textAlign="center">
          Add Slide
        </ModalHeader>
        <ModalBody padding="20px">
          <Box>
            {/* ts-ignore */}
            <FakeSlide onClick={() => onAdd({ sections: defaultSlideMap.basic() as Section[], type: 'basic' })}>
              Basic Slide
            </FakeSlide>
            {/* ts-ignore */}
            <FakeSlide onClick={() => onAdd({ sections: defaultSlideMap.code() as Section[], type: 'basic' })}>
              Code Slide
            </FakeSlide>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
