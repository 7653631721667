const handler = async (result: Response) => {
  if (!result.ok) {
    // FIXME: https://swr.vercel.app/docs/error-handling Should be able to catch this in useSWR
    // const error = new Error('An error occurred while fetching the data.');
    // // Attach extra info to the error object.
    // // @ts-ignore
    // error.cause = (await result.json()).error;
    // // @ts-ignore
    // error.status = result.status;
    // throw error;
  }

  let data = [];
  try {
    data = await result.json();
  } catch (e) {
    console.error('Error while parsing json', e);
    throw e;
  }
  return data;
};

export const getFetcher = async (url: string) => {
  const result = await fetch(url);

  return handler(result);
};

export const postFetcher = (body: string) => async (url: string) => {
  const result = await fetch(url, { body, method: 'POST' });

  return handler(result);
};
