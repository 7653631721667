import { Avatar, Flex, Icon, Menu, MenuDivider, MenuItem, MenuList, Text } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { Button } from 'components/Button/Button';
import { IconBarButton } from 'components/IconBar/IconBarButton';
import { MenuButton } from 'components/Menu/subcomponents/MenuButton';
import { useDeckContext } from 'contexts/DeckContext';
import { useNotificationContext } from 'contexts/NotificationContext';
import { useIdentity } from 'hooks/useIdentity';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import { BiGridVertical as OpenIcon, BiPlus } from 'react-icons/bi';
import { FcIdea } from 'react-icons/fc';
import { useNavigate, useParams } from 'react-router-dom';

const NetlifyMenuWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const Link: React.FC<any> = ({ children, onClick }) => (
  <Text as="span" onClick={onClick} textDecoration="underline" style={{ cursor: 'pointer' }}>
    {children}
  </Text>
);

export const UserMenu: React.FC = () => {
  const { isLoggedIn, login, logout, signup, user } = useIdentity();
  const { username } = useParams();
  const { activeDeck, decks, createDeck, readonly } = useDeckContext();
  const { closeNotice, notify } = useNotificationContext();

  useKeyboardShortcut('newdeck', createDeck);

  useEffect(() => {
    if (isLoggedIn) {
      closeNotice('login-banner');
    } else {
      notify({
        id: 'login-banner',
        message: (
          <Flex display="inline-flex" alignItems="center">
            <Icon as={FcIdea} /> &nbsp;{' '}
            <div>
              <Link onClick={login}>Log in</Link> or <Link onClick={signup}>create an account</Link>{' '}
              {readonly ? 'to create your own slide deck!' : 'to save your changes'}
            </div>
          </Flex>
        ),
        type: 'banner',
      });
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const navigate = useNavigate();

  return user ? (
    <Menu placement="bottom-start">
      <MenuButton>
        <Flex alignItems="center" gridGap="var(--chakra-space-4)">
          <Avatar name={user.user_metadata.full_name} size="sm" />
          <IconBarButton colorScheme="whiteAlpha" label="" icon={OpenIcon} minWidth="auto" />
        </Flex>
      </MenuButton>
      <MenuList boxShadow="var(--sii-boxshadow-light)">
        <MenuItem command="⇧⌘D" icon={<BiPlus />} onClick={createDeck}>
          New Deck
        </MenuItem>
        <MenuDivider />
        {map(decks, (deck: Deck, id: string) =>
          deck.readonly ? null : (
            <MenuItem
              // FIXME: command shortcuts don't work
              // command={`⌘${idx++}`}
              isDisabled={activeDeck?.id === id}
              onClick={() => navigate(`/u/${username}/d/${deck.slug || id}`)}
              key={id}
            >
              {deck.title}
            </MenuItem>
          ),
        )}
        <MenuDivider />
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <NetlifyMenuWrapper>
      <Button fontWeight={400} onClick={login} variant="ghost">
        Login
      </Button>
      <Button onClick={signup} colorScheme="brand">
        Sign up
      </Button>
    </NetlifyMenuWrapper>
  );
};
