import { Flex, useDisclosure } from '@chakra-ui/react';
import { AddSlideModal } from 'components/AddSlideModal/AddSlideModal';
import { BasicSlide } from 'components/BasicSlide/BasicSlide';
import { Draggable } from 'components/Draggable/Draggable';
import { SlideThumb } from 'components/SlideThumb/SlideThumb';
import { useDeckContext } from 'contexts/DeckContext';
import { EditableProvider } from 'contexts/EditableContext';
import { useToolbar } from 'contexts/ToolbarContext';
import { motion } from 'framer-motion';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { usePagination } from 'hooks/usePagination';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BiPlus as PlusIcon } from 'react-icons/bi';
import { log } from 'utils/logger';
import { reorderItems } from 'utils/reorderItems';
import styles from './DeckDrawer.module.scss';

export const DeckDrawer = () => {
  const { isOpen } = useDisclosure({ isOpen: true });
  const { isOpen: isModalOpen, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure();
  const { activeDeck: { slides } = { slides: [] }, readonly, saveSlides } = useDeckContext();
  const [activeSlide, { goto }] = usePagination();
  const { setActiveToolbar } = useToolbar();
  // We need to keep a copy of slides in state for dnd
  const [orderedSlides, setOrderedSlides] = useState(slides);

  useKeyboardShortcut('newslide', onOpenModal);

  useEffect(() => {
    setOrderedSlides(slides);
  }, [slides]);

  // eslint-disable-next-line
  const moveSlide = useCallback(
    debounce((dragIndex: number, hoverIndex: number) => {
      setOrderedSlides((slideList) => reorderItems(dragIndex, hoverIndex, slideList));
    }, 200),
    [],
  );

  const thumbnails = useMemo(() => {
    return (
      orderedSlides.length &&
      orderedSlides.map((slideConfig, idx) => {
        if (!slideConfig.type) {
          throw new Error(`Slide ${slideConfig.id} is missing a type`);
        }

        return (
          <Draggable
            accept="slidedrawer"
            id={`${slideConfig.id}-draggable-thumb`}
            index={idx}
            key={`${slideConfig.id}-thumb`}
            onDrag={moveSlide}
            onDragEnd={() => saveSlides(orderedSlides)}
          >
            <motion.div whileHover={{ scale: 1.03 }}>
              <SlideThumb
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  log('clicking slide thumb');
                  setActiveToolbar(undefined);
                  goto(slideConfig.order);
                }}
                isActive={activeSlide === idx}
                slideId={slideConfig.id}
              >
                <BasicSlide isThumbnail slide={slideConfig} />
              </SlideThumb>
            </motion.div>
          </Draggable>
        );
      })
    );
  }, [activeSlide, goto, moveSlide, saveSlides, setActiveToolbar, orderedSlides]);

  return (
    <EditableProvider editMode={false}>
      <motion.div
        animate={isOpen ? 'open' : 'closed'}
        className={styles.drawer}
        variants={{
          open: { x: 0 },
          closed: { x: '-100%' },
        }}
      >
        <Flex
          className="custom-scroll"
          gridGap="14px"
          flexDirection="column"
          height="100%"
          overflowX="hidden"
          padding="var(--chakra-space-4) var(--chakra-space-4)"
          paddingRight="var(--chakra-space-4)"
        >
          {isOpen ? thumbnails : null}
          {!readonly && (
            <SlideThumb alignItems="center" display="flex" justifyContent="center" onClick={onOpenModal}>
              <PlusIcon color="var(--chakra-colors-text-200)" size="36px" />
            </SlideThumb>
          )}
        </Flex>
      </motion.div>
      <AddSlideModal isOpen={isModalOpen} onClose={onCloseModal} onSuccess={() => goto(slides.length)} />
    </EditableProvider>
  );
};
