// Creates a copy of a deck and all its slides, sections, and blocks while removing the user content and ids
export const cloneDeck = (deck: Deck) => {
  const newDeck = {
    ...deck,
    created_at: undefined,
    id: undefined,
    updated_at: undefined,
    user: undefined,
    userId: undefined,
    slides: deck.slides.map((slide: Slide) => ({
      ...slide,
      created_at: undefined,
      id: undefined,
      updated_at: undefined,
      sections: slide.sections.map((section: Section) => ({
        ...section,
        created_at: undefined,
        id: undefined,
        slideId: undefined,
        updated_at: undefined,
        blocks: section.blocks.map((block: Block) => ({
          ...block,
          created_at: undefined,
          id: undefined,
          updated_at: undefined,
          sectionId: undefined,
        })),
      })),
    })),
  };
  return newDeck;
};
