import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Library } from 'components/Library/Library';

export type ContentProps = {
  style?: React.CSSProperties;
  title?: string;
  titleLink?: string;
  width?: string;
};

type Props = {
  slideId: string;
  isThumbnail: boolean;
  links?: { title: string; url: string }[];
  width?: string;
};

export const Slide: React.FC<Props> = ({ children, isThumbnail, links, slideId, width: widthProp }) => {
  const width = widthProp ? widthProp : isThumbnail ? 'calc(var(--thumbnail-width) * 10)' : '100%';

  return (
    <Box
      height={isThumbnail ? 'calc(var(--thumbnail-height) * 10)' : 'var(--slider-height)'}
      data-id={`slide-${slideId}${isThumbnail ? '-thumbnail' : ''}`}
      maxW={width}
      minH="100%" // useful in fullscreen mode
      minW={0}
      overflow="hidden"
      padding={0}
      style={isThumbnail ? { pointerEvents: 'none' } : {}}
      transform={isThumbnail ? 'scale(var(--drawer-scale))' : undefined}
      transformOrigin="0% 0%"
      width={width}
    >
      <Flex alignItems="center" height="100%" justifyContent="center" width="100%">
        {/* <Content style={isThumbnail ? {} : springProps} title={title} titleLink={titleLink} width={width}> */}
        {children}
        {links && <Library links={links} />}
      </Flex>
    </Box>
  );
};

export type SlideProps = Props;
