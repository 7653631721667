import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { getColor } from './colors';

export const diapoOverrides = {
  colors: {
    brand: {
      25: '#f4fbfa',
      50: '#E0F2F1',
      100: '#B2DFDB',
      200: '#80CBC4',
      300: '#4DB6AC',
      400: '#26A69A',
      500: '#009688',
      600: '#00897B',
      700: '#00796B',
      800: '#00695C',
      900: '#004D40',
    },
    destroy: {
      50: '#fef7f6',
      100: '#fae1db',
      200: '#f3b5a5',
      300: '#ee9781',
      400: '#e76f51',
      500: '#e24d28',
      600: '#b43718',
      700: '#a23216',
      800: '#902c14',
      900: '#6c210f',
    },
    secondary: {
      50: '#ECEFF1',
      100: '#CFD8DC',
      200: '#B0BEC5',
      300: '#90A4AE',
      400: '#78909C',
      500: '#607D8B',
      600: '#546E7A',
      700: '#455A64',
      800: '#37474F',
      900: '#263238',
    },
    text: {
      50: '#ECEFF1',
      100: '#CFD8DC',
      200: '#B0BEC5',
      300: '#90A4AE',
      400: '#78909C',
      500: '#607D8B',
      600: '#546E7A',
      700: '#455A64',
      800: '#37474F',
      900: '#263238',
    },
  },
  components: {
    // @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components
    Button: {
      baseStyle: { fontWeight: 'medium' },
      sizes: { md: { fontSize: '0.9rem', height: '2.5em', lineHeight: '2.5em', px: 6 } },
    },
    Container: { baseStyle: { maxWidth: '80%' } },
    FormLabel: { baseStyle: { color: 'text.300', fontSize: '12px' } },
    Heading: { baseStyle: { textTransform: 'initial' } },
    Input: {
      variants: {
        outline: {
          field: {
            boxShadow: `0 0 0 0 ${getColor('brand.25')}`,
            transition: 'border 200ms ease, box-shadow 400ms ease-in',
            _focus: {
              border: `1px solid ${getColor('brand.300')}`,
              boxShadow: `0 0 0 4px ${getColor('brand.50')}`,
              transition: 'border 100ms ease, box-shadow 200ms ease-out',
            },
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        body: { p: 4 },
        footer: {
          backgroundColor: getColor('brand.25'),
          borderRadius: 'var(--chakra-radii-md)',
          p: 4,
        },
        header: { borderBottom: '1px solid var(--chakra-colors-gray-300)', fontSize: 'medium', textAlign: 'center' },
      },
    },
  },
  fonts: {
    body: 'Inter, "Helvetica Neue", serif',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
};

export const diapoTheme = extendTheme(diapoOverrides, withDefaultColorScheme({ colorScheme: 'brand' }));
