// const timestamp = +new Date();

// const rainbowLaptopold =
//   'https://images.unsplash.com/photo-1580927752452-89d86da3fa0a?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyODQ1NzV8MHwxfHNlYXJjaHw2fHxjb2Rpbmd8ZW58MHx8fHwxNjQxMzA5ODYy&ixlib=rb-1.2.1&q=85';
// const rainbowLaptop =
//   'https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyODQ1NzV8MHwxfHNlYXJjaHw1fHxjb21wdXRlcnxlbnwwfHx8fDE2NDE1MDYyODQ&ixlib=rb-1.2.1&q=85';
// const snowBgImage =
//   'https://images.unsplash.com/photo-1484977975800-86a4c7bd7235?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyODQ1NzV8MHwxfHNlYXJjaHwzfHx3aGl0ZSUyMHNub3d8ZW58MHx8fHwxNjQwMjU5NTE4&ixlib=rb-1.2.1&q=85';
// const yosemite =
//   'https://images.unsplash.com/photo-1604542031658-5799ca5d7936?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyODQ1NzV8MHwxfHNlYXJjaHw0fHx5b3NlbWl0ZXxlbnwwfHx8fDE2NDE3OTIyMjE&ixlib=rb-1.2.1&q=85';

export const defaultSlideMap = {
  basic: () => [
    {
      blocks: [
        {
          order: 0,
          meta: { fontSize: '3xl', fontWeight: 600 },
          type: 'text',
          value: 'Slide Title',
        },
        {
          order: 1,
          type: 'text',
          value: 'Click text to start editing.',
        },
      ],
    },
  ],
  code: () => [
    {
      blocks: [
        {
          type: 'text',
          value: 'Click text to start editing.',
        },
        {
          type: 'code',
          value: `
// Enter code below
      `,
        },
      ],
    },
  ],
};
