import { useBoolean } from '@chakra-ui/hooks';
import React, { createContext, useContext } from 'react';
import { useFullScreenContext } from './FullScreenContext';

type ContextState = { enterEditMode(): void; exitEditMode(): void; inEditMode: boolean };

export const EditableContext = createContext<ContextState>({ inEditMode: false } as ContextState);

type Props = {
  editMode: boolean;
};

export const EditableProvider: React.FC<Props> = ({ children, editMode: editModeProp }) => {
  const [editMode, { off, on }] = useBoolean(editModeProp);
  const { isFullScreen } = useFullScreenContext();

  return (
    <EditableContext.Provider value={{ inEditMode: !isFullScreen && editMode, enterEditMode: on, exitEditMode: off }}>
      {children}
    </EditableContext.Provider>
  );
};

export const useEditableContext = () => {
  return useContext(EditableContext);
};
