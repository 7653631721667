import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useFullScreenContext } from './FullScreenContext';
import { SlideContext } from './SlideContext';
import { useDeckContext } from './DeckContext';

type Toolbar = React.ReactElement;
type ToolbarMap = {
  [key: string]: Toolbar;
};

type ToolbarContextType = {
  activeToolbar?: Toolbar;
  activeToolbarKey?: string;
  addToolbar(toolbarKey: string, toolbar: Toolbar): void;
  setActiveToolbar(toolbarKey?: string): void;
};

export const ToolbarContext = createContext<ToolbarContextType>({} as ToolbarContextType);

export const ToolbarProvider: React.FC = ({ children }) => {
  const { readonly } = useDeckContext();
  const { slideId } = useContext(SlideContext);
  const { isFullScreen } = useFullScreenContext();
  const [activeKey, setActiveKey] = useState<string>();
  const [toolbars, setToolbars] = useState<ToolbarMap>({});
  const addToolbar = useCallback(
    (toolbarKey: string, toolbar: Toolbar) =>
      setToolbars((list) => {
        const newList = cloneDeep(list);
        newList[toolbarKey] = toolbar;
        return newList;
      }),
    [],
  );

  useEffect(() => {
    if (isFullScreen || readonly) {
      setActiveKey(undefined);
    }
  }, [isFullScreen, readonly]);

  useEffect(() => {
    setActiveKey(undefined);
  }, [slideId]);

  return (
    <ToolbarContext.Provider
      value={{
        activeToolbar: activeKey ? toolbars[activeKey] : undefined,
        activeToolbarKey: isFullScreen || readonly ? undefined : activeKey,
        addToolbar,
        setActiveToolbar: setActiveKey,
      }}
    >
      {activeKey}
      {children}
    </ToolbarContext.Provider>
  );
};

export const useToolbar = () => useContext(ToolbarContext);
