import { ColorPanel } from 'components/PropertiesPanel/subcomponents/ColorPanel';
import { PanelTitle } from 'components/PropertiesPanel/subcomponents/PanelTitle';
import { TextPanel } from 'components/PropertiesPanel/subcomponents/TextPanel';
import { SlideContext } from 'contexts/SlideContext';
import { useContext } from 'react';
import { BiFontColor } from 'react-icons/bi';

type Props = { blockId: string; onChange(meta: TextMeta): void; sectionKey: number };

export const EditableTextPanel: React.FC<Props> = ({ blockId, onChange, sectionKey }) => {
  const { currentSlide } = useContext(SlideContext);
  const blocks = currentSlide ? currentSlide.sections[sectionKey].blocks : [];
  const content = blocks.find((c) => blockId === c.id);
  const meta: TextMeta = (content?.meta || {}) as TextMeta;
  // const
  return (
    <>
      <PanelTitle>Text Properties</PanelTitle>
      <ColorPanel
        color={meta.bgColor}
        hasOpacity
        label="Background Color"
        onChange={(bgColor) => onChange({ ...meta, bgColor })}
      />
      <ColorPanel
        color={meta.fontColor}
        icon={BiFontColor}
        label="Font Color"
        onChange={(fontColor) => onChange({ ...meta, fontColor })}
      />
      <TextPanel onChange={onChange} meta={meta} />
    </>
  );
};

export type SectionProps = Props;
