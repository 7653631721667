import { ColorPanel } from 'components/PropertiesPanel/subcomponents/ColorPanel';
import { PanelTitle } from 'components/PropertiesPanel/subcomponents/PanelTitle';

type Props = { meta: CodeMeta; onChange(meta: CodeMeta): void };

export const CodePanel: React.FC<Props> = ({ meta = {}, onChange }) => {
  return (
    <>
      <PanelTitle>Code Properties</PanelTitle>
      <ColorPanel
        color={meta.bgColor}
        hasOpacity
        label="Background Color"
        onChange={(color) => onChange({ ...meta, bgColor: color })}
      />
    </>
  );
};

export type SectionProps = Props;
