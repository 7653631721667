import { AccordionButton, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons/lib';

type Props = {
  icon: IconType;
  label: string;
  onClick?(e: React.SyntheticEvent): void;
  value?: React.ReactNode;
};

export const PanelButton: React.FC<Props> = ({ icon, label, onClick, value }) => {
  return (
    <AccordionButton color="text.500" fontSize="0.875em" fontWeight="500" onClick={onClick}>
      <HStack flex="1" alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" display="flex" gap="4px">
          <Icon as={icon} color="text.200" />
          <Text as="span" whiteSpace="nowrap">
            {label}
          </Text>
        </HStack>
        <Flex alignItems="center" color="brand.500" fontWeight="400">
          <Text as="span" whiteSpace="nowrap">
            {value}
          </Text>
        </Flex>
      </HStack>
    </AccordionButton>
  );
};
