import { PrismTheme } from 'prism-react-renderer';

// TODO
// const _lightThemeColors = {
//   base: '#333',
//   char: '#f00',
//   comment: '#0f0',
//   keyword: '#00f',
//   primitive: '#600',
//   string: '#060',
//   variable: '#006',
//   boolean: '#000',
//   punctuation: '#000',
//   tag: '#000', // HTML tag
//   function: '#000',
//   className: '#000',
//   method: '#000',
//   operator: '#000',
// };

const darkThemeColors = {
  base: '#eff',
  char: '#d8dee9',
  comment: '#546e7a',
  keyword: '#c5a5c5',
  primitive: '#5a9bcf',
  string: '#8dc891',
  variable: '#d7deea',
  boolean: '#ff8b50',
  punctuation: '#5FB3B3',
  tag: '#fc929e', // HTML tag
  function: '#79b6f2',
  className: '#fac863',
  method: '#6699cc',
  operator: '#fc929e',
};

export const codeColors = darkThemeColors;
export const codeTheme: PrismTheme = {
  plain: {
    backgroundColor: 'transparent',
    color: codeColors.base,
    fontFamily: 'var(--sii-font-code)',
    fontSize: '18px',
    lineHeight: '24px',
    padding: 0,
  },
  styles: [
    {
      types: ['attr-name'],
      style: { color: codeColors.keyword },
    },
    {
      types: ['attr-value'],
      style: { color: codeColors.string },
    },
    {
      types: ['comment', 'block-comment', 'prolog', 'doctype', 'cdata'],
      style: { color: codeColors.comment },
    },
    {
      types: ['property', 'number', 'function-name', 'constant', 'symbol', 'deleted'],
      style: { color: codeColors.primitive },
    },
    {
      types: ['boolean'],
      style: { color: codeColors.boolean },
    },
    {
      types: ['tag'],
      style: { color: codeColors.tag },
    },
    {
      types: ['string'],
      style: { color: codeColors.string },
    },
    {
      types: ['punctuation'],
      style: { color: codeColors.string },
    },
    {
      types: ['selector', 'char', 'builtin', 'inserted'],
      style: { color: codeColors.char },
    },
    {
      types: ['function'],
      style: { color: codeColors.function },
    },
    {
      types: ['operator', 'entity', 'url', 'variable'],
      style: { color: codeColors.variable },
    },
    {
      types: ['keyword'],
      style: { color: codeColors.keyword },
    },
    {
      types: ['at-rule', 'class-name'],
      style: { color: codeColors.className },
    },
    {
      types: ['important'],
      style: { fontWeight: '400' },
    },
    {
      types: ['bold'],
      style: { fontWeight: 'bold' },
    },
    {
      types: ['italic'],
      style: { fontStyle: 'italic' },
    },
    {
      types: ['namespace'],
      style: { opacity: 0.7 },
    },
  ],
};
