import { flatten, forEach, isObject, times } from 'lodash';
import { theme } from '@chakra-ui/react';

const baseColors = ['gray', 'red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink'];

export const colors = flatten(baseColors.map((color) => times(10, (i) => `${color}.${i ? `${i}00` : '50'}`)));

export function getColor(color: string) {
  const [shade, lightness] = color.split('.');
  return `var(--chakra-colors-${shade}-${lightness})`;
}

export function getContrastColor(color?: string, separator = '-', readable = false) {
  if (!color) return 'secondary-400';
  const [shade, lightness] = color.split('.');
  const val = parseInt(lightness);
  let finalVal = 0;
  if (readable) {
    finalVal = val <= 300 ? 900 : 50;
  } else {
    finalVal = val === 50 ? val + 350 : val < 400 ? val + 300 : val - 300;
  }
  return `${shade}${separator}${finalVal}`;
}

export function getColorWithIntensity(color: string, intensity: number, separator = '-') {
  const [shade] = color.split('.');
  return `${shade}${separator}${intensity}`;
}

export function getLightestColor(color?: string, separator = '-') {
  if (!color) return 'secondary-50';
  const [shade] = color.split('.');
  return `${shade}${separator}50`;
}

export function getColorFromHex(hex: string) {
  let color = '';
  let newHex = hex;
  if (hex.length === 9) {
    newHex = newHex.slice(0, 7);
  }
  forEach(theme.colors, (values, shade) => {
    if (isObject(values)) {
      forEach(values, (s, lightness) => {
        if (s === newHex) color = `${shade}.${lightness}`;
      });
    }
  });
  return color;
}

export function getRGBA(originalColor: string, opacity: number = 100) {
  const color = originalColor[0] === '#' ? getColorFromHex(originalColor) : originalColor;
  const [shade, lightness] = color.split('.');
  // @ts-ignore
  return `${theme.colors[shade][lightness]}${opacity.toString(16).padStart(2, '0')}`;
}
