import { AccordionItem, AccordionPanel, Avatar, Flex, Image, useDisclosure, VStack } from '@chakra-ui/react';
import { Button } from 'components/Button/Button';
import { ImageModal } from 'components/ImageModal/ImageModal';
import React from 'react';
import { BiImage } from 'react-icons/bi';
import { PanelButton } from './PanelButton';
import { BiImage as ImageIcon } from 'react-icons/bi';

type Props = { label: string; onChange(bgImage?: string, bgImageCredit?: string): void; url?: string };

export const ImagePanel: React.FC<Props> = ({ label = 'Image', onChange, url }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOpen();
  };

  return (
    <AccordionItem>
      <PanelButton icon={BiImage} label={label} value={url && <Avatar src={url} size="2xs" />} />
      <AccordionPanel>
        <VStack spacing={2}>
          {url ? <Image src={url} /> : <ImageIcon color="var(--chakra-colors-gray-300)" size="64px" />}
          <Flex justifyContent="space-between" width="100%">
            <Button onClick={onClick} size="xs" variant="link">
              {url ? 'Edit' : 'Add Image'}
            </Button>
            {url && (
              <Button onClick={() => onChange(undefined, undefined)} size="xs" variant="link">
                Remove
              </Button>
            )}
          </Flex>
        </VStack>
      </AccordionPanel>
      <ImageModal isOpen={isOpen} onChange={onChange} onClose={onClose} url={url} />
    </AccordionItem>
  );
};
