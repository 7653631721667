import { Box, Image, Spinner } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { BiTrash as TrashIcon } from 'react-icons/bi';
import { Credit } from '../../Credit/Credit';
import { IconBar } from '../../IconBar/IconBar';
import { IconBarButton } from '../../IconBar/IconBarButton';
import { ImageMenu } from '../../ImageMenu/ImageMenu';

type Props = SectionContentProps & {
  meta?: ImageMeta;
  onSubmit(url: string, username: ImageMeta): void;
};

const ImageWrapper = styled(Box)`
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: 0.4s ease border, 0.4s ease box-shadow;

  img {
    max-height: 50vh; // FIXME just an estimate
  }

  &:hover {
    border-color: var(--chakra-colors-secondary-400);
    box-shadow: 0 0 0 4px var(--chakra-colors-secondary-200);

    .credit {
      visibility: visible;
    }
  }
`;

export const ImageDisplay: React.FC<Props> = ({ meta: { username } = {}, onRemove, onSubmit, value }) => (
  <ImageWrapper position="relative">
    <Box position="absolute" right="10px" top="10px">
      <IconBar
        buttons={[
          <ImageMenu label="Image URL" onChange={(url, username) => onSubmit(url, { username })} url={value} />,
          <IconBarButton icon={TrashIcon} label="Delete" onClick={onRemove} />,
        ]}
        label="Image"
        openLeft
      />
    </Box>
    <Image alt="Placeholder" fallback={<Spinner />} src={value} />
    {username && <Credit longCredit showOnHover user={{ username }} />}
  </ImageWrapper>
);
