import { ComponentWithAs, MenuButton as Component, MenuButtonProps } from '@chakra-ui/react';

/**
 * This wrapper is needed to avoid a console error. Rendering the `MenuButton` as a
 * `div` so that there is no `button` inside `button`.
 */
export const MenuButton: ComponentWithAs<any, MenuButtonProps> = ({ children, ...props }) => (
  <Component as="div" className="menu-button" {...props}>
    {children}
  </Component>
);
