import { AccordionItem, AccordionPanel, Flex, HStack, Text } from '@chakra-ui/react';
import { FontFamilyMenu } from 'components/FontFamilyMenu/FontFamilyMenu';
import { FontSizeMenu } from 'components/FontSizeMenu/FontSizeMenu';
import { FontWeightMenu } from 'components/FontWeightMenu/FontWeightMenu';
import { IconBarButton } from 'components/IconBar/IconBarButton';
import { capitalize } from 'lodash';
import {
  BiAlignLeft as LeftAlignIcon,
  BiAlignMiddle as MiddleAlignIcon,
  BiAlignRight as RightAlignIcon,
  BiFont,
  BiFontFamily,
  BiFontSize,
  BiTrash,
} from 'react-icons/bi';
import { PanelButton } from './PanelButton';

type AlignmentType = 'center' | 'left' | 'right';
const alignments: AlignmentType[] = ['left', 'center', 'right'];
const iconMap = {
  center: MiddleAlignIcon,
  left: LeftAlignIcon,
  right: RightAlignIcon,
};

type Props = {
  onChange(meta: TextMeta): void;
  meta?: TextMeta;
};

export const TextPanel: React.FC<Props> = ({ meta = {}, onChange }) => {
  return (
    <>
      <AccordionItem>
        <PanelButton
          icon={BiFontFamily}
          label="Font Family"
          value={<Text fontFamily={meta.fontFamily}>{meta.fontFamily}</Text>}
        />
        <AccordionPanel>
          <Flex alignItems="center">
            <FontFamilyMenu onChange={(fontFamily) => onChange({ ...meta, fontFamily })} />
            <Text color={meta.fontFamily ? 'brand.500' : 'text.300'}>{meta.fontFamily || 'Font Family'}</Text>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <PanelButton icon={BiFontSize} label="Font Size" value={meta.fontSize} />
        <AccordionPanel>
          <Flex alignItems="center" gap="10px">
            <FontSizeMenu fontSize={meta.fontSize} onChange={(fontSize) => onChange({ ...meta, fontSize })} />
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <PanelButton icon={BiFont} label="Font Weight" value={meta.fontWeight} />
        <AccordionPanel>
          <Flex alignItems="center" gap="10px">
            <FontWeightMenu fontWeight={meta.fontWeight} onChange={(fontWeight) => onChange({ ...meta, fontWeight })} />
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <PanelButton icon={LeftAlignIcon} label="Text Align" value={meta.textAlign} />
        <AccordionPanel>
          <HStack justifyContent="center" spacing={1}>
            {alignments.map((a: AlignmentType) => (
              <IconBarButton
                disabled={meta.textAlign === a}
                icon={iconMap[a]}
                key={iconMap[a].toString()}
                label={capitalize(a)}
                onClick={() => onChange({ ...meta, textAlign: a })}
              />
            ))}
            <IconBarButton
              disabled={!meta.textAlign}
              icon={BiTrash}
              label="Clear"
              onClick={() => onChange({ ...meta, textAlign: undefined })}
            />
          </HStack>
        </AccordionPanel>
      </AccordionItem>
    </>
  );
};
