import { ButtonGroup, Textarea, TextareaProps, VStack } from '@chakra-ui/react';
import { IconBarButton } from 'components/IconBar/IconBarButton';
import React from 'react';
import { BiBold as BoldIcon, BiCode, BiItalic, BiLink } from 'react-icons/bi';
import { log } from 'utils/logger';

type WrapTextConfig = {
  append?: string;
  id: string;
  wrap: string;
  wrapEnd?: string;
};

function wrapText({ append = '', id, wrap, wrapEnd }: WrapTextConfig) {
  var textArea = document.getElementById(id) as HTMLTextAreaElement;
  var text = textArea.value;
  var start = textArea.selectionStart;
  var end = textArea.selectionEnd;
  return text.substr(0, start) + wrap + text.substr(start, end - start) + (wrapEnd || wrap) + append + text.substr(end);
}

type Props = TextareaProps & {
  customButtons?: React.ReactElement[];
  id: string;
  innerRef: React.MutableRefObject<any>;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};
type EventType = React.ChangeEvent<HTMLTextAreaElement>;

export const MarkdownTextarea: React.FC<Props> = ({ customButtons, id, innerRef, onBlur, onChange, ...props }) => {
  const wrap = (val: string, valEnd?: string, append?: string) =>
    onChange({ target: { value: wrapText({ append, id, wrap: val, wrapEnd: valEnd }) } } as EventType);

  log('MarkdownTextarea.value', props.value);
  return (
    <VStack align="start" height="100%" spacing={4}>
      <ButtonGroup display="flex" justifyContent="space-between" width="100%">
        <ButtonGroup>
          <IconBarButton label="Bold" icon={BoldIcon} onClick={() => wrap('**')} />
          <IconBarButton label="Italic" icon={BiItalic} onClick={() => wrap('_')} />
          <IconBarButton label="Code" icon={BiCode} onClick={() => wrap('`')} />
          <IconBarButton label="Link" icon={BiLink} onClick={() => wrap('[', ']', '(url)')} />
        </ButtonGroup>
        <ButtonGroup>{customButtons}</ButtonGroup>
      </ButtonGroup>
      <Textarea
        height="100%"
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={() => innerRef.current?.select()}
        ref={innerRef}
        width="100%"
        {...props}
      />
    </VStack>
  );
};
