import { useCallback } from 'react';

export const SLIDES_KEY = 'slides';
export const ACTIVE_SLIDE_KEY = 'active_slide';

export const useLocalStorage = () => {
  const get = useCallback(<T = string>(itemKey: string): T | undefined => {
    const item = localStorage.getItem(itemKey);
    try {
      return item ? JSON.parse(item) : undefined;
    } catch (e) {
      // FIXME not sure about this type fix?
      return (item as unknown as T) || undefined;
    }
  }, []);

  const remove = useCallback((itemKey) => localStorage.removeItem(itemKey), []);
  const set = useCallback((itemKey, item) => localStorage.setItem(itemKey, JSON.stringify(item)), []);

  return { get, remove, set };
};
