import { Box, Circle } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { AddContentIconBar } from 'components/AddContentIconBar/AddContentIconBar';
import { CodeDisplay } from 'components/BlockList/subcomponents/CodeDisplay';
import { ImageDisplay } from 'components/BlockList/subcomponents/ImageDisplay';
import { ListDisplay } from 'components/BlockList/subcomponents/ListDisplay';
import { Draggable } from 'components/Draggable/Draggable';
import { Editable } from 'components/Editable/Editable';
import { Text } from 'components/Text/Text';
import { useEditableContext } from 'contexts/EditableContext';
import { SlideContext } from 'contexts/SlideContext';
import { motion } from 'framer-motion';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { log } from 'utils/logger';
import { reorderItems } from 'utils/reorderItems';

const IconCircle: React.FC<any> = ({ Icon = () => null }) => (
  <Circle size="80px" color="white">
    <Icon size="36px" />
  </Circle>
);

const Quote = styled(Text)`
  border-left: 4px solid var(--chakra-colors-secondary-200);
  color: var(--chakra-colors-text-500);
  padding: 1rem;
`;

const SectionContentWrapper = styled.div`
  background-color: transparent;
  // border: 1px dashed var(--contrast-color);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

type Props = {
  blocks: Block[];
  sectionId: string;
  sectionIdx: number;
  slideId: string;
};

const ContentMap: { [key: string]: React.FC<SectionContentProps> } = {
  code: CodeDisplay,
  iconCircle: IconCircle,
  image: ImageDisplay,
  list: ListDisplay,
  // @ts-ignore
  text: (props) => <Editable components={{ Text }} {...props} />,
  // @ts-ignore
  quote: (props) => <Editable components={{ Text: Quote }} {...props} />,
};

export const BlockList: React.FC<Props> = ({ blocks, slideId, sectionId, sectionIdx }) => {
  const { deleteBlock, updateBlock, updateSection } = useContext(SlideContext);
  const { inEditMode } = useEditableContext();
  const [dirtyContent, setDirtyContent] = useState(blocks);
  // log('SectionContent.render', content[0]?.value);

  // eslint-disable-next-line
  const moveBlock = useCallback(
    debounce((dragIndex: number, hoverIndex: number) => {
      setDirtyContent((c) => reorderItems(dragIndex, hoverIndex, c).map((block, idx) => ({ ...block, order: idx })));
    }, 200),
    [],
  );

  const saveContent = useCallback(
    () => updateSection({ section: { id: sectionId, blocks: dirtyContent } }),
    [dirtyContent, sectionId, updateSection],
  );

  useEffect(() => {
    setDirtyContent(blocks);
  }, [blocks]);

  return (
    <motion.div
      animate="blockVisible"
      style={{ display: 'grid', gap: '20px' }}
      variants={{
        blockVisible: {
          transition: {
            staggerChildren: 0.3,
          },
        },
      }}
    >
      {dirtyContent.map((contentConfig: Block, idx: number) => {
        const Component = ContentMap[contentConfig.type] || ContentMap.text;

        const el = (
          <Component
            contentId={contentConfig.id}
            key={`${contentConfig.id}-component`}
            inEditMode={inEditMode}
            meta={contentConfig.meta || {}}
            onRemove={() => deleteBlock({ blockId: contentConfig.id, sectionIdx })}
            onSubmit={async (newValue: string, newMeta: TextMeta) => {
              log('SectionContent.updateBlock submitting:', newValue, newMeta);
              await updateBlock({
                block: { ...contentConfig, ...(newMeta ? { meta: newMeta } : {}), value: newValue },
              });
            }}
            sectionKey={sectionIdx}
            slideId={slideId}
            value={contentConfig.value}
          />
        );

        return inEditMode ? (
          <Draggable
            accept={`${sectionIdx}`}
            id={`${contentConfig.id}-container`}
            index={idx}
            key={`${contentConfig.id}-container`}
            onDrag={moveBlock}
            onDragEnd={saveContent}
            sectionKey={sectionIdx}
          >
            <SectionContentWrapper>{el}</SectionContentWrapper>
          </Draggable>
        ) : (
          el
        );
      })}
      {inEditMode && (
        <Box>
          <AddContentIconBar nextBlockIdx={dirtyContent.length} sectionId={sectionId} />
        </Box>
      )}
    </motion.div>
  );
};
