import { Button, Flex, Input, VStack } from '@chakra-ui/react';
import { Alert } from 'components/Alert/Alert';
import { useConfirmDeleteDialog } from 'hooks/useConfirmDeleteDialog';
import { useCallback, useState } from 'react';
import { BiErrorAlt as WarningIcon } from 'react-icons/bi';
import { log } from 'utils/logger';
import { trim } from 'lodash';
import { slugify } from 'utils/slugify';

type Props = {
  alert?: string;
  deck?: Deck;
  isNew?: boolean;
  onClose(): void;
  onDelete?(id: string): void;
  onSubmit(deck: Partial<Deck>): void;
};

export const DeckSettingsForm: React.FC<Props> = ({ alert, deck, isNew, onClose, onDelete, onSubmit }) => {
  const [title, setTitle] = useState<string>(deck?.title || '');
  const [slug, setSlug] = useState<string>(deck?.slug || '');
  log('DeckSettingsForm.render', title);

  const { confirmDialog } = useConfirmDeleteDialog();
  const confirmDelete = useCallback(async () => {
    if (onDelete && deck?.id) {
      await onDelete(deck?.id);
      onClose();
    }
  }, [deck, onClose, onDelete]);

  return (
    <VStack spacing={4}>
      {alert ? <Alert content={alert} icon={WarningIcon} variant="neutral" /> : null}
      <Input
        autoFocus
        onChange={(e) => {
          setSlug(slugify(e.currentTarget.value));
          setTitle(e.currentTarget.value);
        }}
        placeholder="Enter Slide Deck Title"
        value={title}
      />
      <Input disabled placeholder="Slide URL path" value={slug} />

      <Flex justifyContent="space-between" width="100%">
        {!isNew && onDelete ? (
          <Button
            colorScheme="red"
            onClick={async () => {
              const confirmed = await confirmDialog(
                `Are you sure you want to discard this deck? ${deck?.slides?.length} slides will be deleted.`,
              );
              confirmed && confirmDelete();
            }}
            variant="ghost"
          >
            Delete
          </Button>
        ) : (
          // HACK: Push Update button over to right
          <div></div>
        )}
        <Button disabled={trim(title) === deck?.title || !title.length} onClick={() => onSubmit({ title })}>
          {isNew ? 'Create' : 'Save Changes'}
        </Button>
      </Flex>
    </VStack>
  );
};
