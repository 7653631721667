import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { KeyboardShortcutModal } from 'components/KeyboardShortcutModal/KeyboardShortcutModal';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { WelcomeModal } from 'components/WelcomeModal/WelcomeModal';
import { AppProvider } from 'contexts/AppProvider';
import { diapoTheme } from 'utils/theme';
import { TopMenu } from 'components/TopMenu/TopMenu';
import { getFetcher } from 'utils/fetchers';

window.com = { sii: {} };

export function AppPageLayout() {
  return (
    <ChakraProvider theme={diapoTheme}>
      <SWRConfig
        value={{
          fetcher: getFetcher,
          suspense: true,
        }}
      >
        <Suspense
          fallback={
            <div style={{ height: '100vh', width: '100vw' }}>
              <Spinner /> App loading...
            </div>
          }
        >
          <AppProvider>
            <main className="page">
              <TopMenu />
              <Outlet />
            </main>
            <WelcomeModal />
            <KeyboardShortcutModal />
          </AppProvider>
        </Suspense>
      </SWRConfig>
      {/* </ErrorBoundary> */}
    </ChakraProvider>
  );
}
