import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useBoolean,
} from '@chakra-ui/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { MarkdownTextarea } from 'components/MarkdownTextarea/MarkdownTextarea';
import { useToolbar } from 'contexts/ToolbarContext';
import { useConfirmDeleteDialog } from 'hooks/useConfirmDeleteDialog';
import { debounce, isEqual } from 'lodash';
import { marked } from 'marked';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BiTrash as TrashIcon } from 'react-icons/bi';
import { log } from 'utils/logger';
import { useEditableContext } from '../../contexts/EditableContext';
import { IconBarButton } from '../IconBar/IconBarButton';
import { EditableTextPanel } from './subcomponents/EditableTextPanel';

type Props = SectionContentProps & {
  components: { Text: React.FC<SectionContentTextProps> };
  componentProps: any;
};

const EditableWrapper = styled('div', { shouldForwardProp: (p: string) => isPropValid(p) && p !== 'loading' })<{
  loading?: boolean;
  readonly?: boolean;
  selected?: boolean;
}>`
  --shadow-width: 2px;
  --box-shadow: 0 0 0 var(--shadow-width) var(--contrast-color);
  --box-shadow-selected: 0 0 0 var(--shadow-width) var(--chakra-colors-brand-500);
  ${(p) => (p.selected ? 'box-shadow: var(--box-shadow-selected);' : 'box-shadow: 0 0 0 0 var(--contrast-color);')};
  ${(p) => (p.loading ? 'opacity: 0.25;' : 'opacity: 1;')};
  transition: 300ms ease box-shadow;
  ${({ readonly, selected }) =>
    readonly
      ? 'pointer-events: none;'
      : `  &:hover {
    box-shadow: ${selected ? 'var(--box-shadow-selected)' : 'var(--box-shadow)'};
  }
  `}
  .spinner {
    position: absolute;
    left: -40px;
  }
`;

export const Editable: React.FC<Props> = ({
  components,
  componentProps,
  contentId,
  meta: metaProp = {},
  onSubmit: onSubmitProp,
  onRemove,
  sectionKey,
  slideId,
  value: initialValue = '',
}) => {
  const { inEditMode } = useEditableContext();
  const [loading, { off: stopLoading, on: startLoading }] = useBoolean();
  const [value, setValue] = useState(initialValue);
  const initialFocusRef = useRef<HTMLTextAreaElement>();
  const { Text } = components || { Text: ({ children }) => <div>{children}</div> };
  const { activeToolbarKey, addToolbar, setActiveToolbar } = useToolbar();
  const { confirmDialog } = useConfirmDeleteDialog();
  const [meta, setMeta] = useState(metaProp);
  // const [selected, { off, on }] = useBoolean(activeToolbarKey === contentId);
  useEffect(() => setMeta(metaProp), [metaProp]);

  const selected = useMemo(() => activeToolbarKey === contentId, [activeToolbarKey, contentId]);

  const onSubmit = useMemo(
    () =>
      // TODO: Remember why the debounce is needed
      debounce(async (valueArg, newMetaArg) => {
        startLoading();
        setMeta(newMetaArg);
        await onSubmitProp(valueArg, newMetaArg);
        stopLoading();
      }, 800),
    [onSubmitProp, startLoading, stopLoading],
  );
  const onDelete = useCallback(async () => {
    const confirmed = await confirmDialog(`Are you sure you want to delete this content?`);
    confirmed && onRemove();
  }, [confirmDialog, onRemove]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const textContent = (
    <EditableWrapper loading={loading} readonly={!inEditMode} selected={selected}>
      {loading && <Spinner className="spinner" style={{ color: meta.fontColor }} />}
      <Text
        contentId={contentId}
        htmlContent={marked(value)}
        meta={meta}
        section={sectionKey}
        slideId={slideId}
        {...componentProps}
      />
    </EditableWrapper>
  );

  useEffect(() => {
    // TODO: Adding the component once here is not practical. It's added again when the value changes, but
    // should probably be handled in a ToolbarContext or similar.
    addToolbar(
      contentId,
      <EditableTextPanel
        blockId={contentId}
        onChange={(newMeta: TextMeta) => {
          log('Editable.TextPanel.onChange', newMeta);
          onSubmit(value, newMeta);
        }}
        sectionKey={sectionKey}
      />,
    );
    // eslint-disable-next-line
  }, [value]);

  return inEditMode ? (
    // @ts-ignore
    <Popover arrowSize={14} gutter={30} initialFocusRef={initialFocusRef} isLazy>
      <PopoverTrigger>
        {/* Wrap in div to avoid the following error: Function components cannot be given refs */}
        <div
          onClick={(e) => {
            e.stopPropagation();
            setActiveToolbar(contentId);
          }}
          tabIndex={0}
        >
          {textContent}
        </div>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          boxShadow="var(--sii-boxshadow-light)"
          cursor="default"
          fontFamily="var(--chakra-fonts-body)"
          height="200px"
          width="500px"
        >
          <PopoverArrow />
          <PopoverBody padding="15px">
            <MarkdownTextarea
              color="text.600"
              customButtons={[<IconBarButton key="delete" label="Delete" icon={TrashIcon} onClick={onDelete} />]}
              id={`${contentId}-textarea`}
              onBlur={() => {
                if (!isEqual(initialValue, value)) {
                  onSubmitProp(value);
                  setValue(value);
                }
              }}
              onChange={(e) => setValue(e.target.value)}
              innerRef={initialFocusRef}
              value={value}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  ) : (
    textContent
  );
};
