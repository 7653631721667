import App from 'App';
import { AppPageLayout } from 'components/AppPageLayout/AppPageLayout';
import { DeckViewer } from 'pages/DeckViewer';
import { UserDashboard } from 'pages/UserDashboard';
import { createBrowserRouter, Outlet } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [{ element: <App />, path: '/' }],
  },
  {
    path: '/u',
    element: <AppPageLayout />,
    // errorElement: <ErrorPage />,
    children: [
      {
        element: <UserDashboard />,
        path: '/u/:username',
      },
      {
        element: <DeckViewer />,
        path: '/u/:username/d/:deckId/:slideIdx?',
      },
    ],
  },
  // {
  //   path: '/u/:username/',
  //   element: <UserDashboard />,
  // },
  // {
  //   path: '/u/:username/d/:deckId/:slideIdx?',
  //   element: <App />,
  //   // errorElement: <div>An error happened!</div>,
  // },
  {
    path: '/404',
    element: <div>404</div>,
  },
]);
