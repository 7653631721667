import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { times } from 'lodash';

const FontPreview = styled(Box)`
  --size: 21px;
  background-color: var(--chakra-colors-secondary-100);
  border-radius: 2px;
  color: var(--chakra-colors-text-500);
  height: var(--size);
  overflow: hidden;
  position: relative;
  width: var(--size);
  transition: var(--sii-animation-exit) transform;

  &:hover {
    background-color: var(--chakra-colors-secondary-200);
    transition: var(--sii-animation-enter) transform;
  }

  &:after {
    content: 'Aa';
    line-height: 0.5rem;
    position: absolute;
    bottom: 0.1rem;
    right: 0;
  }
`;

type Props = {
  fontSize?: string;
  onChange(size: string): void;
};

const fontSizes = ['xs', 'sm', 'md', 'lg', 'xl', ...times(5, (i) => `${i + 2}xl`)];

export const FontSizeMenu: React.FC<Props> = ({ fontSize, onChange }) => (
  <VStack>
    {fontSizes.map((size) => (
      <Button
        aria-label={`${size}`}
        leftIcon={<FontPreview fontSize={size} />}
        colorScheme={fontSize === size ? 'brand' : 'gray'}
        isDisabled={fontSize === size}
        key={size}
        onClick={() => onChange(size)}
        variant="outline"
      >
        <Flex justifyContent="space-between" width="100%">
          <Box>{size || 'md'}</Box>
        </Flex>
      </Button>
    ))}
  </VStack>
);
