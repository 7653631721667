import {
  Box,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { RiBook2Fill as LibraryIcon } from 'react-icons/ri';

type LibraryProps = {
  links?: any[];
};

export const Library: React.FC<LibraryProps> = ({ links = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Box position="fixed" right="20px" top="20px">
        <IconButton
          aria-label="More Reading"
          icon={<LibraryIcon size="32px" />}
          // @ts-ignore
          ref={btnRef}
          onClick={onOpen}
        />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        // @ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>More Reading</DrawerHeader>

          <DrawerBody>
            {links?.map((link) => (
              <Link href={link.url} isExternal>
                {link.title}
              </Link>
            ))}
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
