import { flatMap, partition } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { useEffect, useState } from 'react';
import { loadFonts as loadFontsHelper } from 'utils/fonts';
import { log } from 'utils/logger';

const WebFont = require('webfontloader');

type FontContextState = {
  allFonts: GoogleFont[];
  deckFonts: GoogleFont[];
  groupedFonts: { [key: string]: GoogleFont[] };
  loadFonts(fonts: string[]): void;
  fonts: string[];
};

export const FontContext = React.createContext({} as FontContextState);

export const FontProvider: React.FC = ({ children }) => {
  /**
   * TODO: Clean this up. There was a lot of back and forth on how to display these to the user, and
   * settled on groupedFonts, but left behind unused code that may be useful in the future.
   */
  // eslint-disable-next-line
  const [allFonts, setAllFonts] = useState<GoogleFont[]>([]);
  const [groupedFonts, setGroupedFonts] = useState<{ [key: string]: GoogleFont[] }>({});
  const [fonts, setFonts] = useState<string[]>([]);
  const [usedFonts, setUsedFonts] = useState<GoogleFont[]>([]);

  useEffect(() => {
    async function init() {
      // N.B.: This is currently only returning 5 fonts per category to speed up initial load
      const initialFonts = await loadFontsHelper();
      log('FontContext.init', initialFonts);
      setGroupedFonts(initialFonts);
      // Pre-load this initial list of fonts so that they are available to preview in font menu
      const families = flatMap(initialFonts).map((f) => f.family) || [];
      if (families.length) {
        WebFont.load({ google: { families } });
      }
    }
    init();
  }, []);

  const loadFonts = useCallback(
    (families: string[]) => {
      log('FontContext.loadFonts', families);
      if (families.length) {
        WebFont.load({ google: { families } });
        setUsedFonts(partition(allFonts, (googleFont) => families.includes(googleFont.family))[0]);
        setFonts((fontList) => [...fontList, ...families]);
      }
    },
    [allFonts],
  );

  return (
    <FontContext.Provider value={{ allFonts, deckFonts: usedFonts, groupedFonts, fonts, loadFonts }}>
      {children}
    </FontContext.Provider>
  );
};

export const useFontContext = () => useContext(FontContext);
