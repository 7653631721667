import { Box } from '@chakra-ui/react';
import { CodePanel } from 'components/CodeBlock/subcomponents/CodePanel';
import { useToolbar } from 'contexts/ToolbarContext';
import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { CodeBlock } from '../../CodeBlock/CodeBlock';
import { Window } from '../../Window/Window';
import { log } from 'utils/logger';

type Props = SectionContentProps & {
  meta?: CodeMeta;
};

export const CodeDisplay: React.FC<Props> = ({ contentId: id, inEditMode, meta = {}, onSubmit, value }) => {
  const { addToolbar, setActiveToolbar } = useToolbar();

  useEffect(() => {
    addToolbar(id, <CodePanel meta={meta} onChange={(m) => onSubmit(value, m)} />);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const onChange = useCallback(
    debounce((v) => {
      log('CodeDisplay.onSubmit', v);
      onSubmit(v);
    }, 1000),
    [onSubmit],
  );

  return (
    <Box position="relative">
      <Window
        bgColor={meta?.bgColor}
        onClick={(e) => {
          e.stopPropagation();
          setActiveToolbar(id);
        }}
        title={meta.title}
      >
        <CodeBlock code={value} disabled={!inEditMode} onChange={onChange} />
      </Window>
    </Box>
  );
};
