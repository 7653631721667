import { useDisclosure } from '@chakra-ui/react';
import { useCallback } from 'react';

function closeFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    //@ts-expect-error
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    //@ts-expect-error
    document.webkitExitFullscreen();
    //@ts-expect-error
  } else if (document.msExitFullscreen) {
    /* IE11 */
    //@ts-expect-error
    document.msExitFullscreen();
  }
}

function openFullScreen(elem: HTMLElement, onClose?: () => void) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
    //@ts-expect-error
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    //@ts-expect-error
    elem.webkitRequestFullscreen();
    //@ts-expect-error
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    //@ts-expect-error
    elem.msRequestFullscreen();
  }

  const exitHandler = () => {
    // @ts-ignore
    if (onClose && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      onClose();
    }
  };

  if (document.addEventListener) {
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
  }
}

export const useFullScreen = () => {
  const { isOpen, onClose: exit, onOpen } = useDisclosure();

  const exitFullScreen = useCallback(() => {
    exit();
    closeFullScreen();
  }, [exit]);

  const open = useCallback(
    (e: HTMLElement) => {
      onOpen();
      openFullScreen(e, exit);
    },
    [exit, onOpen],
  );

  return { exitFullScreen, enterFullScreen: open, isFullScreen: isOpen };
};
