import { Menu, MenuDivider, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import { useFontContext } from 'contexts/FontContext';
import { map } from 'lodash';
import React from 'react';
import { BiFontFamily as FontFamilyIcon, BiTrash as ClearIcon } from 'react-icons/bi';
import { fontCategoryMap } from 'utils/fonts';
import { IconBarButton } from '../IconBar/IconBarButton';
import { MenuButton } from '../Menu/subcomponents/MenuButton';

type Props = {
  fontFamily?: string;
  onChange(size?: string): void;
};

export const FontFamilyMenu: React.FC<Props> = ({ fontFamily, onChange }) => {
  const { loadFonts, groupedFonts, deckFonts } = useFontContext();

  return (
    <Menu isLazy>
      <MenuButton as={IconBarButton} label="Font Family" icon={FontFamilyIcon} />
      <MenuList color="text.500" fontSize="sm" maxHeight="var(--slider-height)" overflowY="scroll">
        <MenuItem icon={<ClearIcon />} onClick={() => onChange(undefined)}>
          Clear
        </MenuItem>
        {deckFonts.length ? (
          <>
            <MenuDivider />
            <MenuGroup title="Recent Fonts">
              {deckFonts.map((f) => (
                <MenuItem
                  isDisabled={fontFamily === f.family}
                  fontFamily={f.family}
                  key={f.family}
                  onClick={() => {
                    loadFonts([f.family]);
                    onChange(f.family);
                  }}
                >
                  {f.family}
                </MenuItem>
              ))}
            </MenuGroup>
          </>
        ) : null}
        {map(groupedFonts, (group: GoogleFont[], key: string) => (
          <React.Fragment key={key}>
            <MenuDivider />
            <MenuGroup title={fontCategoryMap[key]}>
              {group.map((f) => (
                <MenuItem
                  isDisabled={fontFamily === f.family}
                  fontFamily={f.family}
                  key={f.family}
                  onClick={() => {
                    loadFonts([f.family]);
                    onChange(f.family);
                  }}
                >
                  {f.family}
                </MenuItem>
              ))}
            </MenuGroup>
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};
