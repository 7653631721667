import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IdentityContextProvider } from 'react-netlify-identity';
import { DeckProvider } from './DeckContext';
import { FontProvider } from './FontContext';
import { FullScreenProvider } from './FullScreenContext';
import { NotificationProvider } from './NotificationContext';

import { SlideProvider } from './SlideContext';
import { ToolbarProvider } from './ToolbarContext';

// const imgUrl =
//   'https://cdn.dribbble.com/assets/landing-pages/jobs/jobs-intro-image-375w-afe5e7da66f183a627a9d045e22bfbb93aeae7a946779124b2b79e58bc2d05d9.jpg';

export const AppProvider: React.FC = ({ children }) => {
  return (
    <IdentityContextProvider url="https://www.sliides.io">
      <NotificationProvider>
        <FontProvider>
          <DeckProvider>
            <SlideProvider>
              <FullScreenProvider>
                <DndProvider backend={HTML5Backend}>
                  <ToolbarProvider>{children}</ToolbarProvider>
                </DndProvider>
              </FullScreenProvider>
            </SlideProvider>
          </DeckProvider>
        </FontProvider>
      </NotificationProvider>
    </IdentityContextProvider>
  );
};
