import { DeckSettingsForm } from 'components/DeckSettingsModal/DeckSettingsForm';
import { FormModal } from 'components/FormModal/FormModal';
import { useIdentity } from 'hooks/useIdentity';
import React from 'react';
import { log } from 'utils/logger';

type Props = {
  deck?: Deck;
  isOpen: boolean;
  onClose(): void;
  onDelete?(id: string): void;
  onSave(config: Partial<Deck>): void;
};
export const DeckSettingsModal: React.FC<Props> = ({ deck, isOpen = false, onDelete, onClose, onSave }) => {
  const { isLoggedIn } = useIdentity();
  const isNew = !deck?.id;

  log('DeckSettingsModal.render');

  return (
    <>
      <FormModal
        header={isNew ? 'Create Deck' : 'Deck Settings'}
        isOpen={isOpen}
        onClose={onClose}
        tabs={[
          {
            content: (
              <DeckSettingsForm
                alert={
                  !isLoggedIn
                    ? 'Log in or create an account to make sure that your changes are saved across devices'
                    : undefined
                }
                deck={deck}
                isNew={isNew}
                onClose={onClose}
                onDelete={onDelete}
                onSubmit={({ title }) => {
                  onSave({ title });
                  onClose();
                }}
              />
            ),
            title: 'General',
          },
        ]}
      />
    </>
  );
};
