import { Box, BoxProps } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { DeckContext } from 'contexts/DeckContext';
import { useConfirmDeleteDialog } from 'hooks/useConfirmDeleteDialog';
import React, { useCallback, useContext } from 'react';
import { BiX as CloseIcon } from 'react-icons/bi';
import { IconButton } from '../IconButton/IconButton';

type Props = BoxProps & {
  isActive?: boolean;
  slideId?: string;
};

const Thumbnail = styled(Box, { shouldForwardProp: (p) => p !== 'isActive' })<Props>`
  border-radius: 0.375rem;
  height: var(--thumbnail-height);
  width: var(--thumbnail-width);
  overflow: hidden;
  transition: 0.4s ease border, 0.4s ease box-shadow;

  &:hover {
    border-color: var(--chakra-colors-secondary-300);
    box-shadow: 0 0 0 4px var(--chakra-colors-secondary-100);
  }

  ${(p) =>
    p.isActive &&
    `& {
    border-color: var(--chakra-colors-brand-400);
    box-shadow: 0 0 0 4px var(--chakra-colors-brand-200);
  }`}
`;

const CloseButton = styled(IconButton)`
  font-size: 16px;
  opacity: 1;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: var(--z-index-close);
`;

const ThumbnailWrapper = styled(Box)`
  --z-index-screen: 1;
  --z-index-close: 2;
  cursor: pointer;
  width: var(--thumbnail-width);

  &:before {
    position: absolute;
    inset: 0;
    border-radius: 6px;
    background-color: white;
    opacity: 0.2;
    transition-property: opacity;
    transition-duration: 0.15s;
    pointer-events: none;
    transition-timing-function: ease;
    content: '';
    z-index: var(--z-index-screen);
  }

  &:hover {
    &:before {
      opacity: 0;
    }
    ${CloseButton} {
      background: white;
      color: var(--chakra-colors-brand-400);
      border-radius: 5px;
      opacity: 1;
    }
  }
`;

export const SlideThumb: React.FC<Props> = ({ children, isActive, onClick, slideId, ...props }) => {
  const { confirmDialog } = useConfirmDeleteDialog();
  const { deleteSlide } = useContext(DeckContext);

  const remove = useCallback(async () => {
    if (await confirmDialog('Are you sure you want to delete this slide?')) {
      slideId && deleteSlide(slideId);
    }
  }, [confirmDialog, deleteSlide, slideId]);

  return (
    <ThumbnailWrapper position="relative">
      {slideId && (
        <CloseButton
          aria-label="Delete Slide"
          // color="white"
          colorScheme="whiteAlpha"
          icon={<CloseIcon />}
          onClick={remove}
          size="xxs"
          variant="outline"
        />
      )}
      <Thumbnail onClick={onClick} {...props} isActive={isActive}>
        {children}
      </Thumbnail>
    </ThumbnailWrapper>
  );
};
